import React, {useCallback, useEffect, useState} from 'react';
// import {createVeriffFrame, MESSAGES} from '@veriff/incontext-sdk';
import {useDispatch, useSelector} from "react-redux";
import {useParams, useNavigate} from "react-router-dom";
// import {Veriff} from '@veriff/js-sdk';

import {Button, Icon, Messages} from "../../common";
import SmsCode from "./SMSCode";
import PhoneNumber from "./PhoneNumber";

import {
    getUserDataPending,
    postAuthorizePending,
    postGenerateOtpPending,
    resetPhoneNumberState,
    // updateKycStatusPending,
    verifyOtpPending,
    clearOtpCode
} from "../../../store/actions";
import {getAuthorizeToken, getKycStatus, getOtp, getUserData} from "../../../store/selectors";

import styles from "../../../assets/styles/business.module.scss";
import cx from 'classnames'
import { getCurrentUser } from "utils/auth";


// let regId = '';
const Identity = () => {
    const {userId, token} = useParams()
    const dispatch  = useDispatch()
    const navigate = useNavigate()

    const {data} = useSelector(getUserData)
    const {authorizeToken} = useSelector(getAuthorizeToken)
    const {message, error} = useSelector(getOtp)
    const {phoneNumberSubmitted} = useSelector(getKycStatus)

    const [phoneNumber, setPhoneNumber] = useState('')
    const [phoneCode, setPhoneCode] = useState('+44')
    const [codeLabel, setCodeLabel] = useState('')
    const [otpCode, setOtpCode] = useState('')
    const [startCount, setStartCount] = useState(false)
    const [disableSend, setDisableSend] = useState(false)
    const [changed, setChanged] = useState(false)
    const currentUser = getCurrentUser();

    const emailAddress = currentUser.email ? currentUser.email : currentUser.user.email;
    const env = localStorage.getItem('envName')

    useEffect(() => {
        if (token && token !== '1') {
            dispatch(postAuthorizePending({token: token, user_id: userId}))
        } else {
            localStorage.removeItem('authorizeToken')
        }
        dispatch(getUserDataPending(userId))
        dispatch(clearOtpCode())
    }, [dispatch, userId, token])


    useEffect(()=> {
        if (env === 'dev' || env === 'test' || env === 'undefined') {
            setPhoneNumber("12345678")
            setCodeLabel("+44")
        }
    }, [env])

    useEffect(() => {
        if (phoneNumberSubmitted) {
            setStartCount(true)
            setDisableSend(true)
            dispatch(resetPhoneNumberState())
        }
    }, [phoneNumberSubmitted, dispatch])

    // useEffect(() => {
    //     if ((env === "test" || env === "dev" || env === "undefined") && codeLabel === "+44" && phoneNumber === "12345678") {
    //         setOtpCode("123456")
    //         // setDisableSend(true)
    //     }
    // }, [env, codeLabel, phoneNumber])

    const redirectUser = useCallback(() => {
        const url = data.email === emailAddress ? '/dashboard/home' : `/set-password/${userId}/${token}`
        navigate(url)
    }, [data, emailAddress, navigate, userId, token])

    // useEffect(() => {
    //     if (data?.id && data?.id !== regId) {
    //         // wait for ID to be available
    //         regId = data.id;
    //         const options = {
    //             name: data.first_name,
    //             user_profile_id: data.id,
    //             user_id: userId,
    //             company: company_id,
    //             email: data.email
    //         }
    //         const veriff = Veriff({
    //             host: 'https://alchemy.veriff.com',
    //             apiKey: '6a4ab4df-b8ea-4a49-9258-1296c4d604ba',
    //             parentId: 'veriff-root',
    //             onSession: function (err, response) {
    //
    //                 if (response?.verification?.status) {
    //                     dispatch(updateKycStatusPending({...options, status: response?.verification?.status}))
    //                 }
    //                 createVeriffFrame({
    //                     url: response?.verification?.url,
    //                     onEvent: function (msg) {
    //                         switch (msg) {
    //                             // case MESSAGES.STARTED:
    //                             //     break;
    //
    //                             case MESSAGES.CANCELED:
    //                                 dispatch(updateKycStatusPending({...options, status: 'cancelled'}))
    //                                 break;
    //
    //                             case MESSAGES.FINISHED:
    //                                 dispatch(updateKycStatusPending({...options, status: 'submitted'}))
    //                                 redirectUser()
    //                                 break;
    //
    //                             default:
    //                                 // setOpened(false);
    //                                 break
    //                         }
    //                     }
    //                 })
    //                 // window.veriffSDK.createVeriffFrame({ url: response.verification.url });
    //             }
    //         });
    //         veriff.setParams({
    //             person: {
    //                 givenName: data.first_name,
    //                 lastName: data.last_name,
    //             },
    //             vendorData:  "'" + authorizeToken + "'",
    //         });
    //         veriff.mount();
    //     }
    // }, [data, userId, company_id, dispatch, redirectUser, authorizeToken])


    const clearOtpData = useCallback(() => {
        dispatch(clearOtpCode())
    }, [dispatch])

    const handleChange = useCallback((value, code) => {
        setChanged(true)
        setPhoneNumber(value)
        setCodeLabel(code?.dialCode)
        setPhoneCode(value)
        setDisableSend(false)
        setStartCount(false)
        clearOtpData()
    }, [clearOtpData])

    const handleNumberChange = useCallback((event) => {
        resetPhoneNumberState()
        setPhoneNumber(event?.target?.value || '')
        setDisableSend(false)
        setStartCount(false)
        clearOtpData()
    }, [clearOtpData])

    const handleSend = useCallback(() => {
        const number = phoneNumber.replace(/^0+/, '');
        const option = {token: authorizeToken, phone_number: `${codeLabel}${number}`}
        dispatch(postGenerateOtpPending(option))
        if ((env === "test" || env === "dev" || env === "undefined") && codeLabel === "+44" && phoneNumber === "12345678") {
            setOtpCode("123456")
        } else {
            setOtpCode('')
        }
    }, [dispatch, codeLabel, phoneNumber, authorizeToken, env])

    useEffect(() => {
        if(otpCode.length === 6) {
            const option = {token: authorizeToken, otp: otpCode}
            dispatch(verifyOtpPending(option))
        }
    }, [otpCode, dispatch, authorizeToken])

    return (
        <>
            {error && <Messages text={error} variant={'error'}/>}
            <div className={cx(styles.veriff)}>
                <h3>Verify your information</h3>
                <div className={cx(styles.passed)}>
                    <Icon name={'passed'} className={cx(styles.completed)}/>
                    <Icon name={'horizontal-dashes'}/>
                    <Icon name={'passed'} className={cx(styles.completed)}/>
                    <Icon name={'horizontal-dashes'}/>
                    <Icon name={'passed'} className={cx(styles.completed)}/>
                    <Icon name={'horizontal-dashes'}/>
                    <Icon name={'passed'} className={cx(styles.completed)}/>
                    <Icon name={'horizontal-dashes'}/>
                    <Icon name={'last-step'} style={{marginLeft: '-6px'}} className={cx(styles.completed)}/>
                </div>
                <div className={cx(styles.mobileNumber)}>
                    <h4>Add your mobile number</h4>
                    <PhoneNumber
                        onChange={handleChange}
                        onNumberChange={handleNumberChange}
                        value={phoneCode}
                        phoneNumber={phoneNumber}
                        changed={changed}
                    />
                    {/*<button*/}
                    {/*    className={cx(styles.submit, !phoneNumber || disableSend || !codeLabel ? styles.submitDisable : styles.submitActive)}*/}
                    {/*    type={'button'}*/}
                    {/*    onClick={handleSend}*/}
                    {/*    disabled={!phoneNumber || disableSend || !codeLabel}*/}
                    {/*>*/}
                    {/*    Send*/}
                    {/*</button>*/}
                    <Button
                        text={'Send'}
                        size={'lg'}
                        margin={{top: '40px'}}
                        disabled={!phoneNumber || disableSend }
                        onClick={handleSend}
                    />
                    <SmsCode
                        phoneNumber={phoneNumber}
                        otpCode={otpCode}
                        setOtp={setOtpCode}
                        startCount={startCount}
                        message={message}
                        onChange={clearOtpData}
                        resend={handleSend}
                    />
                    {/*<h4>Complete verification</h4>*/}
                    {/*<p>As a last step of onboarding, we need to take you through a simple verification process. You will*/}
                    {/*    need your mobile phone and digital copy of ID to complete verification.</p>*/}
                    {/*<button*/}
                    {/*    type={"button"}*/}
                    {/*    disabled={ message !== 'otp_verified'}*/}
                    {/*    className={cx(styles.submit, message === 'otp_verified' ? styles.submitActive : styles.submitDisable)}*/}
                    {/*    onClick={() => redirectUser()}>*/}
                    {/*    /!*{message !== 'otp_verified' ? <Icon name={'loading'}/> : 'Submit'}*!/*/}
                    {/*    Submit*/}
                    {/*</button>*/}
                    <p className={cx(styles.terms)}>By clicking on Submit you are agreeing to the <a href={'/terms-and-conditions'}>Terms and Conditions</a> for using the services of Merge and its Partners. </p>
                    <Button
                        text={'Submit'}
                        size={'lg'}
                        margin={{top: '40px'}}
                        disabled={message !== 'otp_verified'}
                        onClick={() => redirectUser()}
                    />
                </div>

                {/*<div id={'veriff-root'} className={message === 'otp_verified' ? '' : 'disabled'}/>*/}
            </div>
        </>
    );
};

export default Identity;