const Endpoints = {
    home: {
        heroSection: {
            url: `/hero-section?populate=*`,
            auth: false,
        },
        companyLogos: {
            url: `/company-logos?populate=*`,
            auth: false,
        },
        featureSections: {
            url: `/feature-sections?populate=*`,
            auth: false,
        },
        transactionsFeatures: {
            url: `/transaction-features?populate=*`,
            auth: false,
        },
        developerSection: {
            url: `/developer-section`,
            auth: false,
        },
        solutions: {
            url: `/solutions?populate=*`,
            auth: false,
        },
        insights: {
            url: `/feature?populate=*`,
            auth: false,
        },
        teamMembers: {
            url: `/teams?populate=*`,
            auth: false,
        },
        ourTeamSection: {
            url: `/our-team?populate=*`,
            auth: false,
        },
        footerSection: {
            url: `/footer?populate=*`,
            auth: false,
        },
    },
    recipients: {
        recipient: {
            url: '/recipient',
            auth: true,
        },
        getRecipient: {
            url: (currency = 'GBP') => `/recipient?currency=${currency}&member_type=business`,
            auth: true,
        },
    },
    developer: {
        createApiKey: {
            url: '/api-token-management/token',
            auth: true,
        },
        getApiKey: {
            url: '/api-token-management/token',
            auth: true,
        },
        deactivateApiKey: {
            url: (id) => `/api-token-management/token/${id}`,
            auth: true
        }
    },
    accounts: {
        account: {
            url: '/account',
            auth: true,
        },
        accountDetails: {
            url: (account_id) => `account/${account_id}`,
            auth: true,
        },
        accounts: {
            url:  (type, currency, page = 1, size= 10, status, search) => `/account?type=${type}${currency ? '&currency='+currency : ''}&page=${page}&size=${size}${status ? '&status='+status : ''}${search ? '&search='+search : ''}&member_type=business`,
            auth: true,
        },
        subAccounts: {
            url:  (type, page, size,) => `/account?type=${type}&page=${page}&size=${size}&member_type=business`,
            auth: true,
        },
        transactions: {
            url:  (account_id, page, size, queryParams) => `account/${account_id}/transaction?page=${page}&size=${size}${queryParams ? '&' + queryParams : ''}`,
            auth: true,
        },
        transactionFee: {
            url:   (account_id, amount) => `account/${account_id}/transaction-fee?amount=${amount}`,
            auth: true,
        }
    },
    otp: {
        otp: {
            url: '/otp',
            auth: true,
        },
    },
    featureFlags: {
        feature: {
            url: '/feature',
            auth: true,
        },
    },
    transactions: {
        transaction: {
            url: (account_type, page = 1, size= 10, queryParams, currency = 'GBP') => `/transaction?account_type=${account_type}&currency=${currency}&page=${page}&size=${size}${queryParams ? '&' + queryParams : ''}`,
            auth: true,
        },
    },
    auth: {
        updatePassword: {
            url: '/auth/update-password',
            auth: true,
        }
    },
    payment_request: {
        get: {
            url: (page = 1, size= 10, queryParams, currency = 'GBP') => `/payment-request?currency=${currency}&page=${page}&size=${size}${queryParams ? '&' + queryParams : ''}`
        },
        post: {
            url: '/payment-request'
        }
    }
}

export default Endpoints;