import React, {useCallback, useEffect} from 'react';
import {Button, CustomList, InputField} from "components/common";
import {Avatar, Box, Grid, List, ListItem, ListItemAvatar, ListItemText} from "@mui/material";
import cx from "classnames";
import styles from "../send-funds.module.scss";
import {actions as recipientsActions} from "components/dashboard/recipients/slice";
import { useDispatch, useSelector } from "react-redux";
import recipientsSelect from 'components/dashboard/recipients/selectors'
import  {
    actions
} from "../slice";
import { useNavigate } from "react-router-dom";
import Skeleton from '@mui/material/Skeleton';
import { EURAccount, GBPAccount, StyledAccountInfo } from 'components/dashboard/Dashboard';
import { getCurrencyData } from 'store/selectors/currency';
import selectState from '../selectors';


const SelectRecipient = () => {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const {currency} = useSelector(getCurrencyData);
    const {fromAccount} = selectState();

    const [searchTerm, setSearchTerm] = React.useState("");
    const [recipients, setRecipients] = React.useState([]);

    const {
        loading,
        recipients: {
            items
        },
    } = recipientsSelect();
    const handleChange = value => {
        setSearchTerm(value);
    };

    useEffect(() => {
        if(searchTerm.length > 3) {
            const results = items.filter(person =>
                person.account_holder_name.toLowerCase().includes(searchTerm.toLowerCase())
            );

            setRecipients(results);
        } else {
            setRecipients(items);
        }
    }, [searchTerm, items, currency]);

    useEffect(() => {
        dispatch(recipientsActions.getRecipients());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, currency]);


    const getSubtitle = useCallback((recipient) => {
        var subtitle = '';
        if (recipient.account_number) {
            subtitle += `Acc-number: ${recipient.account_number}`;
        }
        if (recipient.sort_code) {
            subtitle += `, Sort code: ${recipient.sort_code}`;
        }
        if (recipient.iban) {
            subtitle += `IBAN: ${recipient.iban}`;
        }
        if (recipient.bic) {
            subtitle += `, BIC: ${recipient.bic}`;
        }
        return subtitle;
    }, []);

    const handleSelectRecipient = (recipient) => {
        dispatch(actions.setSelectedRecipient(recipient));
        dispatch(actions.setCurrentFundStep(1));
    };


  return (
      <Grid item xs={12} md={12} lg={12} sm={12}>
          <Grid container>
              <Grid item md={12} xs={12} sm={12} lg={12} xl={6}>
                  <InputField
                      placeholder="Search"
                      hasIcon
                      iconName="search"
                      iconPosition="start"
                      value={searchTerm}
                      onChange={(e) => handleChange(e.target.value)}
                  />
              </Grid>
          </Grid>
          <Grid container mt={6} mb={4} style={{"margin": "15px 0px"}}>
              <Grid item xs={9} sm={6} md={3} lg={3} xl={2} style={{"padding": "0px 10px"}}>
                  <h2 className={cx(styles['stepper-title'])}>
                      Select Recipient
                  </h2>
              </Grid>
              <Grid item xs={12} sm={6} md={9} lg={9} xl={4} style={{"paddingTop": "10px"}}>
                  <Box display="flex" justifyContent="flex-end">
                    <StyledAccountInfo 
                        style={{'height': '80px', 'paddingTop':'10px', 'width': 'auto', 'minWidth': '240px'}}>
                        <h5>Sending funds from: </h5>
                        <div>
                            { currency === 'GBP' && <GBPAccount accounts={[fromAccount]}/>}
                            { currency === 'EUR' && <EURAccount accounts={[fromAccount]}/>}
                        </div>
                    </StyledAccountInfo>
                </Box>
            </Grid>
          </Grid>
          <Grid container mb={5}>
              <Grid item md={12} xs={12} sm={12} lg={12} xl={6}>
                  {loading ? (
                          <List>
                              <ListItem>
                                  <ListItemAvatar>
                                      <Avatar>
                                          <Skeleton variant="circular" width={40} height={40} />
                                      </Avatar>
                                  </ListItemAvatar>
                                  <ListItemText>
                                      <Skeleton  variant="text" />
                                      <Skeleton  variant="text" />
                                  </ListItemText>
                              </ListItem>
                              <ListItem>
                                  <ListItemAvatar>
                                      <Avatar>
                                          <Skeleton variant="circular" width={40} height={40} />
                                      </Avatar>
                                  </ListItemAvatar>
                                  <ListItemText>
                                      <Skeleton  variant="text" />
                                      <Skeleton  variant="text" />
                                  </ListItemText>
                              </ListItem>
                              <ListItem>
                                  <ListItemAvatar>
                                      <Avatar>
                                          <Skeleton variant="circular" width={40} height={40} />
                                      </Avatar>
                                  </ListItemAvatar>
                                  <ListItemText>
                                      <Skeleton  variant="text" />
                                      <Skeleton  variant="text" />
                                  </ListItemText>
                              </ListItem>
                          </List>
                  ) :
                    <>
                        {recipients.length > 0 &&
                            <div className={cx(styles['list-wrapper'])}>
                                {recipients.map(recipient => (
                                    <CustomList
                                        key={recipient.id}
                                        title={`${recipient.account_holder_name}` + (recipient.nickname ? ` (Nickname: ${recipient.nickname})`: '')}
                                        subtitle={getSubtitle(recipient)}
                                        avatar={"true"}
                                        customClass={cx(styles['list'])}
                                        avatarClass={cx(styles['avatar'])}
                                        onClick={() => {
                                            handleSelectRecipient(recipient);
                                        }}
                                    />
                                ))}
                            </div>
                        }
                    </>
                  }
              </Grid>
          </Grid>
          <Grid container>
              <Grid item md={12} xs={12} sm={12} lg={12} xl={6}>
                  <Button
                      text="+ Add Recipient"
                      variant="outlined"
                      onClick={() => {
                          dispatch(recipientsActions.addRecipientPage(undefined));
                          dispatch(recipientsActions.addRecipientFromSendFundsPage({fromAccount}));
                          navigate("/dashboard/recipients", { replace: true });
                      }}
                  />
              </Grid>
          </Grid>
      </Grid>
  );
};


export default SelectRecipient;