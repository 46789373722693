import {call, put, select, takeLatest} from 'redux-saga/effects';
import {actions} from "./slice";
import { request } from 'utils/api';
import Endpoints from 'utils/endpoints';
import {getAccessToken} from "utils/auth";
import {getCompanyData} from "store/selectors";

const { getApiKey, deactivateApiKey, createApiKey } = Endpoints.developer;


export function* getApiKeys(action) {
    try {
        const apiKeys = yield call(request().get, getApiKey.url);
         if(apiKeys && apiKeys.data && apiKeys.status >=200 && apiKeys.status <300) {
          yield put(actions.getApiKeysSuccess(apiKeys.data));
         } else {
            yield put(actions.getApiKeysError({
                message: 'No API Keys',
                status: apiKeys.status,
            }));
         }
    } catch (err) {
        yield put(actions.getApiKeysError({
            message: err.message,
            status: 403,
        }));
    }
}

export function* getOtp(action) {
    const companyData = yield select(getCompanyData);
    const createPayload = {display_name: companyData?.data?.legal_name || ''};
    
    try {
        const response = yield call(
            request().post,
            'auth/generate-request-otp',
            {
                message: '{otp} is your One Time Password. Never share this code.',
                subject: 'API Key Requested',
                request: {
                    path: '//v1/api-token-management/token',
                    method: 'POST',
                    payload: createPayload,
                },
                token: getAccessToken(),
            },
        );
        if(response) {
            yield put(actions.getOtpSuccess(response.data));
        }
    } catch (err) {
        yield put(
            actions.getOtpError({
                message: err.message,
                status: 403,
            }));
    }
}

export function* getDeactivateOtp(action) {
    const {payload: {apiKeyId}} =  action;
    try {
        const response = yield call(
            request().post,
            'auth/generate-request-otp',
            {
                message: '{otp} is your One Time Password. Never share this code.',
                subject: 'Request to deactivate API Key',
                request: {
                    path: `//v1/api-token-management/token/${apiKeyId}`,
                    method: 'DELETE',
                    payload: {},
                },
                token: getAccessToken(),
            },
        );
        if(response) {
            yield put(actions.getDeactivateOtpSuccess(response.data));
        }
    } catch (err) {
        yield put(
            actions.getDeactivateOtpError({
                message: err.message,
                status: 403,
            }));
    }
}


export function* createApiKeyWorker(action) {
    const companyData = yield select(getCompanyData);
    const createPayload = {display_name: companyData?.data?.legal_name || ''};    
    try {
        const apiKeys = yield call(request().post, createApiKey.url, createPayload, {
            headers: {
                "X_OTP_CODE": action.payload.OTP,
                "X_OTP_TOKEN": action.payload.token
            },
        });
         if(apiKeys && apiKeys.data && apiKeys.status >=200 && apiKeys.status <300) {
          yield put(actions.createApiKeySuccess(apiKeys.data));
         } else {
            yield put(actions.createApiKeyError({
                message: 'Failed to create API Key',
                status: apiKeys.status,
            }));
         }
    } catch (err) {
        yield put(actions.createApiKeyError({
            message: 'Invalid OTP',
            status: 403,
        }));
    }
}

export function* deactivateApiKeyWorker(action) {
    try {
        const apiKeys = yield call(request().delete, deactivateApiKey.url(action.payload.apiKeyId), {
            headers: {
                "X_OTP_CODE": action.payload.deactivateOtp,
                "X_OTP_TOKEN": action.payload.deactivateToken
            },
        });
         if(apiKeys && apiKeys.status >=200 && apiKeys.status <300) {
          yield put(actions.deactivateApiKeySuccess());
         } else {
            yield put(actions.deactivateApiKeyError({
                message: 'Failed to deactivate API key',
                status: apiKeys.status,
            }));
         }
    } catch (err) {
        yield put(actions.deactivateApiKeyError({
            message: 'Invalid OTP',
            status: 403,
        }));
    }
}


export function* developerSaga() {
    yield takeLatest(actions.getApiKeys.type, getApiKeys);
    yield takeLatest(actions.deactivateApiKey.type, deactivateApiKeyWorker);
    yield takeLatest(actions.getDeactivateOtp.type, getDeactivateOtp);
    yield takeLatest(actions.createApiKey.type, createApiKeyWorker);
    yield takeLatest(actions.getOtp.type, getOtp);
}


