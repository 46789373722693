import React, {useEffect} from 'react';

import { useDispatch, useSelector } from "react-redux";
import {actions} from "../slice";
import selectState from '../selectors';
import cx from "classnames";
import { Icon } from "components/common";
import styles from "../send-funds.module.scss";
import NumberFormat from "react-number-format";
import { Grid } from "@mui/material";
import { getCurrencyData } from 'store/selectors/currency';

const ReviewDetail = () => {
    const dispatch = useDispatch();
    const {
        fromAccount,
        transaction_details,
        selectedRecipient,
        transaction_fee,
    } = selectState();
    const {currency} = useSelector(getCurrencyData);
    const [fromAccountName, setFromAccountName] = React.useState('');

    useEffect(() => {
        setFromAccountName(fromAccount && (fromAccount.account_holder_name || fromAccount.account_name || fromAccount.client_account_name));
    }, [fromAccount]);

    return (
        <>
                <Grid container mb={4}>
                    <Grid item md={12} xs={12} sm={12} lg={12} xl={6}>
                        <h2 className={cx(styles['stepper-title'])}>
                            Review
                        </h2>
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item md={12} xs={12} sm={12} lg={12} xl={6} className={cx(styles['review-step'])}>
                        <div className={cx(styles['review-sub-title'])}>
                            <div>Transfer details</div>
                            <div className={cx(styles['review-edit-icon'])} onClick={() => {
                                dispatch(actions.setIsEditTransaction(true))
                                dispatch(actions.setCurrentFundStep(1))
                            }}>
                                Edit <Icon name="edit" color="#00A09B" />
                            </div>
                        </div>
                        <div className={cx(styles['review-detail-label'])}>
                            <div>You send:</div>
                            <span className={cx(styles['review-detail-item'])} style={{ float: 'right' }}>
                                  {transaction_details && transaction_fee &&
                                      <NumberFormat
                                          value={parseFloat(transaction_details.amount).toFixed(2)}
                                          thousandSeparator={true}
                                          displayType={'text'} />
                                  } {currency}
                            </span>
                        </div>
                        <div className={cx(styles['review-detail-label'])}>
                            <div>Recipient receives:</div>
                            <div className={cx(styles['review-detail-item'])} style={{ float: 'right' }}>
                                {transaction_details &&
                                    <NumberFormat
                                        value={parseFloat(transaction_details.amount).toFixed(2)}
                                        thousandSeparator={true}
                                        displayType={'text'} />
                                } {currency}
                            </div>
                        </div>
                        <div className={cx(styles['review-detail-label'])}>
                            <div>Reference:</div>
                            <div className={cx(styles['review-detail-item'])} style={{ float: 'right' }}>
                                {transaction_details && transaction_details.reference}
                            </div>
                        </div>
                        <div className={cx(styles['review-detail-label'])}>
                            <div>Balance after transfer:</div>
                            <div className={cx(styles['review-detail-item'])} style={{ float: 'right' }}>
                                {(transaction_details && fromAccount) && (
                                    <NumberFormat
                                        value={parseFloat(parseFloat(fromAccount.balance) - parseFloat(transaction_details.amount)).toFixed(2)}
                                        thousandSeparator={true}
                                        displayType={'text'} />
                                    )} {currency}
                            </div>
                        </div>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item md={12} xs={12} sm={12} lg={12} xl={6} className={cx(styles['review-step'])}>
                        <div className={cx(styles['review-sub-title'])}>
                            <div>Recipient details</div>
                            <div className={cx(styles['review-edit-icon'])} onClick={() => dispatch(actions.setCurrentFundStep(0))}>
                                Change
                                <Icon name="edit" color="#00A09B" />
                            </div>
                        </div>
                        <div className={cx(styles['review-detail-label'])}>
                            <div>Name:</div>
                            <div className={cx(styles['review-detail-item'])} style={{ float: 'right' }}>
                                {selectedRecipient && selectedRecipient.account_holder_name}
                            </div>
                        </div>
                        <div className={cx(styles['review-detail-label'])}>
                            <div style={{minWidth: 150}}>Email Address:</div>
                            <div className={cx(styles['review-detail-item'])} style={{ float: 'right' }}>
                                {selectedRecipient ? selectedRecipient.email : "-"}
                            </div>
                        </div>
                        {selectedRecipient?.account_number && <div className={cx(styles['review-detail-label'])}>
                            <div>Account Number:</div>
                            <div className={cx(styles['review-detail-item'])} style={{ float: 'right' }}>
                                {selectedRecipient && selectedRecipient.account_number}
                            </div>
                        </div>}
                        { selectedRecipient?.sort_code && <div className={cx(styles['review-detail-label'])}>
                            <div>Sort code:</div>
                            <div className={cx(styles['review-detail-item'])} style={{ float: 'right' }}>
                                {selectedRecipient ? selectedRecipient.sort_code : "-"}
                            </div>
                        </div>}
                        {selectedRecipient?.iban && <div className={cx(styles['review-detail-label'])}>
                            <div>IBAN:</div>
                            <div className={cx(styles['review-detail-item'])} style={{ float: 'right' }}>
                                {selectedRecipient && selectedRecipient.iban}
                            </div>
                        </div>}
                        {selectedRecipient?.bic && <div className={cx(styles['review-detail-label'])}>
                            <div>BIC:</div>
                            <div className={cx(styles['review-detail-item'])} style={{ float: 'right' }}>
                                {selectedRecipient && selectedRecipient.bic}
                            </div>
                        </div>}
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item md={12} xs={12} sm={12} lg={12} xl={6} className={cx(styles['review-step'])}>
                        <div className={cx(styles['review-sub-title'])}>
                            <div>Remitter details</div>
                            <div className={cx(styles['review-edit-icon'])} onClick={() => dispatch(actions.setCurrentFundStep(0))}>
                                Change
                                <Icon name="edit" color="#00A09B" />
                            </div>
                        </div>
                        {fromAccountName && <div className={cx(styles['review-detail-label'])}>
                            <div>Name:</div>
                            <div className={cx(styles['review-detail-item'])} style={{ float: 'right' }}>
                            {fromAccountName}
                            </div>
                        </div>}
                        {fromAccount?.account_number && <div className={cx(styles['review-detail-label'])}>
                            <div>Account Number:</div>
                            <div className={cx(styles['review-detail-item'])} style={{ float: 'right' }}>
                                {fromAccount && fromAccount.account_number}
                            </div>
                        </div>}
                        { fromAccount?.sort_code && <div className={cx(styles['review-detail-label'])}>
                            <div>Sort code:</div>
                            <div className={cx(styles['review-detail-item'])} style={{ float: 'right' }}>
                                {fromAccount ? fromAccount.sort_code : "-"}
                            </div>
                        </div>}
                        {fromAccount?.iban && <div className={cx(styles['review-detail-label'])}>
                            <div>IBAN:</div>
                            <div className={cx(styles['review-detail-item'])} style={{ float: 'right' }}>
                                {fromAccount && fromAccount.iban}
                            </div>
                        </div>}
                        {fromAccount?.bic && <div className={cx(styles['review-detail-label'])}>
                            <div>BIC:</div>
                            <div className={cx(styles['review-detail-item'])} style={{ float: 'right' }}>
                                {fromAccount && fromAccount.bic}
                            </div>
                        </div>}
                    </Grid>
                </Grid>
        </>
    );
}

export default ReviewDetail;