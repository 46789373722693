import { all } from 'redux-saga/effects';
import {changePassword, forgotPassword, postCheckEmail, postLogin, postSignup, postLoginOtp} from "./auth";
import {getUserData} from "./user";
import {postAuthorize, postGenerateOtp, verifyOtp} from "./kyc";
import {
    getCompanyData, getCompanyTypes, getIndustryType,
    postMembers, updateCompany, getFundSources, getCompanies,
    checkRegistrationNumber
} from "./company";
import {
    deleteCompanyMember, getCompanyMemberData, getCompanyMembers,
    postCompanyMember, updateCompanyMember, registerOwner, createDocument
} from "./companyMembers";
import {recipientsSaga} from "components/dashboard/recipients/sagas";
import { getFeatureFlagsSaga } from 'AppSagas';
import {sendFundsSaga} from "../../components/dashboard/funds/sagas";
import {transactionSaga} from "components/dashboard/transactions/sagas";
import {accountsSaga} from "../../components/dashboard/account/sagas";
import { paymentsSaga } from 'components/dashboard/pendingpayments/sagas';
import { profileSaga } from 'components/dashboard/profile/sagas';
import { developerSaga } from 'components/dashboard/developer/sagas';
import { changeCurrencyType } from './currency';

export default function* rootSaga() {
    yield all([
        postLogin(),
        postLoginOtp(),
        postSignup(),
        updateCompany(),
        getCompanyTypes(),
        getCompanyMembers(),
        postCompanyMember(),
        deleteCompanyMember(),
        updateCompanyMember(),
        getIndustryType(),
        getFundSources(),
        registerOwner(),
        getUserData(),
        getCompanies(),
        forgotPassword(),
        postAuthorize(),
        changePassword(),
        getCompanyData(),
        postCheckEmail(),
        postMembers(),
        getCompanyMemberData(),
        checkRegistrationNumber(),
        recipientsSaga(),
        postGenerateOtp(),
        verifyOtp(),
        getFeatureFlagsSaga(),
        sendFundsSaga(),
        transactionSaga(),
        createDocument(),
        createDocument(),
        accountsSaga(),
        paymentsSaga(),
        profileSaga(),
        developerSaga(),
        changeCurrencyType(),
    ])
}
