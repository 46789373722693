import React, { useCallback, useEffect, useState } from 'react';
import { Grid } from "@mui/material";
import { useFormik } from "formik";
import { Button, InputField, RadioGroup, Loading, CustomAlert, SelectField } from "components/common";
import styles from "../../recipients.module.scss";
import cx from "classnames";
import { renderValidationSchema } from "./validators";
import { useDispatch, useSelector } from 'react-redux';
import { toCapitalize } from 'utils/helpers';
import { actions } from '../../slice';
import selectState from '../../selectors';
import { fundsType, supportedCountries } from '../../mockData';
import NumberInput from "components/common/NumberInput";
import { getCurrencyData } from 'store/selectors/currency';

/**
 * @param {object} props
 * @returns {*}
 * @constructor
 */

const RecipientsAdd = (props) => {
    /* eslint-disable no-unused-vars */
    const {
        cancelAdd
    } = props;
    const dispatch = useDispatch();
    const [checkEmail, setCheckEmail] = useState(true);
    const [numberInput, setNumberInput] = useState({
        sort_code: '',
        account_number: '',
    });
    const { currency } = useSelector(getCurrencyData);

    /* eslint-enable no-unused-vars */

    const {
        loading,
        error,
    } = selectState();

    const formik = useFormik({
        initialValues: {
            email: '',
            first_name: '',
            last_name: '',
            company_name: '',
            country: '',
            account_holder_name: '',
            nickname: '',
            type: '',
            account_number: '',
            sort_code: '',
            iban: '',
            bic: '',
        },
        validationSchema: renderValidationSchema(currency),
        onSubmit: (values) => {
            let filteredValues = Object.fromEntries(Object.entries(values).filter(([_, v]) => v !== ""));
 
            if (values.type === 'person') {
                // Delete company name if unintentionally added
                delete filteredValues.company_name;
            } else if (values.type === 'business') {
                // Delete first name and last name if unintentionally added
                delete filteredValues.first_name;
                delete filteredValues.last_name; 
            }
            // Add currency from the store
            filteredValues = {
                ...filteredValues,
                currency,
            }
            dispatch(actions.createRecipients(filteredValues));
        },
    });

    const handleEmailChange = useCallback((event) => {
        formik.setValues(data => ({ ...data, [event.target.name]: event.target.value?.toLowerCase()?.trim() }));
    }, [formik]);

    const updateToTitleCase = useCallback((event) => {
        formik.setValues(data => ({ ...data, [event.target.name]: toCapitalize(event.target.value) }));
    }, [formik]);

    const updateToUpperCase = useCallback((event) => {
        formik.setValues(data => ({ ...data, [event.target.name]: event.target.value?.toUpperCase() }));
    }, [formik]);

    const trimWhitespaces = useCallback((event) => {
        // Trim the extra spaces around the string and fix the multiple spaces between string using regex
        formik.setValues(data => ({ ...data, [event.target.name]: event.target.value?.trim().replace(/\s+/g, " ") }));
    }, [formik]);

    const removeWhitespaces = useCallback((event) => {
        // Replace spaces with empty string
        formik.setValues(data => ({ ...data, [event.target.name]: event.target.value?.replace(/\s+/g, "") }));
    }, [formik]);

    const handleChange = useCallback((event) => {
        formik.setValues(data => ({ ...data, [event.target.name]: event.target.value }));
    }, [formik]);

    const handleSelect = (name, value) => {
        formik.setValues(data => ({ ...data, [name]: value }));
    }

    const handleEmailCheck = (error) => {
        setCheckEmail(!error);
    }

    const handleNumberChange = (value) => {
        setNumberInput({
            ...numberInput,
            [value.type]: value.name.value
        });
        formik.setValues(data => ({ ...data, [value.type]: value.name.value }));
    }

    useEffect(() => {
        return () => {
            dispatch(actions.addRecipientPageReset());
        }
    }, [dispatch]);

    const isCurrencyOfType = (currencyType) => {
        return currency === currencyType;
    }

    const isAddRecipientButtonDisabled = (values, errors) => {
        var isDisabled = Boolean(errors.type)
                            || Boolean(errors.account_holder_name)
                            || Boolean(errors.country)
                            || Boolean(errors.email);
        switch (currency) {
            case 'GBP':
                isDisabled = isDisabled || Boolean(errors.account_number)
                    || numberInput.account_number.length !== 8
                    || Boolean(errors.sort_code)
                    || numberInput.sort_code.length !== 6;
                break;
            case 'EUR':
                isDisabled = isDisabled || Boolean(errors.iban)
                    || values.iban.length < 8
                    || Boolean(errors.type)
                    || Boolean(errors.account_holder_name);
                break;
            default:
                return true;
        }
        switch (values.type) {
            case 'person':
                isDisabled = isDisabled || Boolean(errors.first_name)
                    || Boolean(errors.last_name);
                break;
            case 'business':
                isDisabled = isDisabled || Boolean(errors.company_name);
                break;
            default:
                return true;
        }
        return isDisabled;
    }

    return (
        <>
            <Grid item xs={12} className={cx(styles.recipients, styles['recipients-add'])}>
                <h2 className={cx(styles.title)}>Add new recipients</h2>
                {error &&
                    <CustomAlert
                        message={"Something went wrong. Please try again later."}
                        severity='error'
                        type='notification'
                        open={error}
                        onClose={() => dispatch(actions.addRecipientPageReset())}
                    />
                }
                {loading && <Loading className={cx(styles.recipientsLoading)} />}
                {!loading &&
                    <Grid item xs={12} className={cx(styles.list)}>
                        <form onSubmit={formik.handleSubmit}>
                            <Grid item md={6}>
                                <RadioGroup
                                    options={fundsType}
                                    label="I am sending funds to a:"
                                    required={true}
                                    onChange={handleChange}
                                    isRow
                                    name={'type'}
                                    error={(formik.touched.type && Boolean(formik.errors.type))}
                                    helperText={formik.touched.type && formik.errors.type}
                                />
                                <InputField
                                    name={'email'}
                                    label={'Email address'}
                                    required={true}
                                    placeholder={'Enter email address'}
                                    onChange={handleEmailChange}
                                    value={formik.values.email}
                                    error={(formik.touched.email && !!formik.errors.email)}
                                    helperText={formik.touched.email && formik.errors.email}
                                    onBlur={(e) => handleEmailCheck(formik.errors.email)}
                                />
                                {!checkEmail && <p className={cx(styles.error)}>{formik.errors.email}</p>}
                                <InputField
                                    name={'nickname'}
                                    label={'Nick name'}
                                    placeholder={'Enter nick name'}
                                    onChange={updateToTitleCase}
                                    value={formik.values.nickname}
                                    error={formik.touched.nickname && Boolean(formik.errors.nickname)}
                                    onBlur={trimWhitespaces}
                                />
                                {formik.values.type === 'person' && <><InputField
                                    name={'first_name'}
                                    label={'First Name'}
                                    placeholder={'Enter first name'}
                                    required={true}
                                    onChange={updateToTitleCase}
                                    value={formik.values.first_name}
                                    error={(formik.touched.first_name && Boolean(formik.errors.first_name))}
                                    helperText={formik.touched.first_name && formik.errors.first_name}
                                    onBlur={trimWhitespaces}
                                />
                                <InputField
                                    name={'last_name'}
                                    label={'Last Name'}
                                    placeholder={'Enter Last name'}
                                    required={true}
                                    onChange={updateToTitleCase}
                                    value={formik.values.last_name}
                                    error={(formik.touched.last_name && Boolean(formik.errors.last_name))}
                                    helperText={formik.touched.last_name && formik.errors.last_name}
                                    onBlur={trimWhitespaces}
                                /></>}
                                {formik.values.type === 'business' && <InputField
                                    name={'company_name'}
                                    label={'Company Name'}
                                    placeholder={'Enter company name'}
                                    onChange={updateToUpperCase}
                                    required={true}
                                    value={formik.values.company_name}
                                    error={(formik.touched.company_name && Boolean(formik.errors.company_name))}
                                    helperText={formik.touched.company_name && formik.errors.company_name}
                                    onBlur={trimWhitespaces}
                                />}
                            </Grid>
                            <Grid container>
                                <Grid item xs={12}>
                                    <h2 className={cx(styles.title)}>Enter Bank details</h2>
                                    <Grid item xs={12} md={6}>
                                        <InputField
                                        name={'account_holder_name'}
                                        label={'Account Holder Name'}
                                        required={true}
                                        placeholder={'Enter the name of account holder'}
                                        onChange={updateToUpperCase}
                                        value={formik.values.account_holder_name}
                                        error={(formik.touched.account_holder_name && Boolean(formik.errors.account_holder_name))}
                                        helperText={formik.touched.account_holder_name && formik.errors.account_holder_name}
                                        onBlur={trimWhitespaces}
                                        />
                                    {isCurrencyOfType('EUR') ? (
                                        <>
                                            <InputField
                                                name={'iban'}
                                                label={'Enter IBAN number'}
                                                required={true}
                                                placeholder={'XX000000000000'}
                                                additionalInputProps={{
                                                    maxLength: 30,
                                                }}
                                                onChange={removeWhitespaces}
                                                value={formik.values.iban}
                                                error={(formik.touched.iban && Boolean(formik.errors.iban))}
                                                helperText={formik.touched.iban && formik.errors.iban}
                                            />
                                            <InputField
                                                name={'bic'}
                                                label={'Enter BIC code'}
                                                placeholder={'XX000000000000'}
                                                additionalInputProps={{
                                                    maxLength: 15,
                                                }}
                                                onChange={removeWhitespaces}
                                                value={formik.values.bic}
                                                error={(formik.touched.bic && Boolean(formik.errors.bic))}
                                                helperText={formik.touched.bic && formik.errors.bic}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <NumberInput
                                                name={'account_number'}
                                                label={'Account number (8 digits)'}
                                                placeholder={'xxxxxxxx'}
                                                value={numberInput.account_number}
                                                onValueChange={(value) => handleNumberChange({ name: value, type: 'account_number' })}
                                                format="########"
                                                required={true}
                                                error={((formik.touched.account_number && Boolean(formik.errors.account_number)) || numberInput.account_number.length !== 8)}
                                                helpertext={(formik.touched.account_number && formik.errors.account_number) || (numberInput.account_number.length !== 8 && formik.touched.account_number) ? 'Please enter a valid account number' : ''}
                                            />
                                            <NumberInput
                                                name={'sort_code'}
                                                label={'Sort code (6 digits)'}
                                                placeholder={'xxxxxx'}
                                                format="######"                                                value={numberInput.sort_code}
                                                onValueChange={(value) => handleNumberChange({ name: value, type: 'sort_code' })}
                                                mask="-"
                                                required={true}
                                                error={((formik.touched.sort_code && Boolean(formik.errors.sort_code)) || numberInput.sort_code.length !== 6)}
                                                helpertext={(formik.touched.sort_code && formik.errors.sort_code) || (numberInput.sort_code.length !== 6 && formik.touched.sort_code) ? 'Please enter a valid UK sort code' : ''}
                                            />
                                        </>)
                                    }
                                        <SelectField
                                            fontIcons
                                            name={'country'}
                                            label={'Select the country'}
                                            placeHolderText ={'Select the country'}
                                            required={true}
                                            options={supportedCountries[currency]}
                                            onChange={handleSelect}
                                            value={formik.values.country}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container mt={2}>
                                <Grid item xs={12} md={6} className={cx(styles.buttonGroups)}>
                                    <Button
                                        variant="outlined"
                                        text="Cancel"
                                        onClick={cancelAdd}
                                    />
                                    <Button
                                        text="Add recipient"
                                        onClick={formik.handleSubmit}
                                        disabled={isAddRecipientButtonDisabled(formik.values, formik.errors)}
                                    />
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                }
            </Grid>
        </>
    )
}



export default RecipientsAdd;
