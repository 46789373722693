import React, { useEffect, useState } from 'react';
import {Grid, List} from "@mui/material";
import cx from "classnames";
import styles from "../../../transactions/transaction.module.scss"
import {CustomList, CustomTooltip} from "../../../../common";
import {toCapitalize} from "../../../../../utils/helpers";
import {useNavigate} from "react-router-dom";
import moment from "moment/moment";
import Icon from '../../../../common/Icon';
import { useSelector } from 'react-redux';
import { getCurrencyData } from 'store/selectors/currency';
import { belongsTo, AllowedUsers } from 'utils/auth';
import {getUserTypes} from "store/selectors/auth"
import NumberFormat from 'react-number-format';

const getSubtitle = (account) => {
    if (account.currency === 'GBP') {
        return `Acc-number: ${account.account_number}, Sort code: ${account.sort_code}`;
    } else if (account.currency === 'EUR') {
        return `IBAN: ${account.iban}, BIC: ${account.bic ?? "N/A"}`;
    }
    return '';
}

const getAccountStatusIcon = (status) => {
    switch (status) {
        case 'pending':
            return 'pending-circle';
        case 'close_initiated': // fall through
        case 'active':
            return 'success-circle';
        case 'closed': // fall through
        case 'create-failed': // fall through
        case 'blocked':
            return 'failed-circle';
        default:
            return '';
    }    
}

const AccountList = ({accounts}) => {
    const navigate = useNavigate();
    const [currencySymbol, setCurrencySymbol] = useState('£');
    const {currency} = useSelector(getCurrencyData);
    const userTypes = useSelector(getUserTypes);

    useEffect(() => {
        setCurrencySymbol(currency === 'GBP'? '£' : '€');
    }, [currency]);

    return (
        <Grid item md={12} xs={12} className={cx(styles.list)} style={{marginTop: '56px'}}>
            <List>
                {accounts?.length > 0 && accounts.map(account => (
                    <React.Fragment key={account.id}>
                        <CustomList
                            id={account.id}
                            key={account.id}
                            title={`${account.account_holder_name || account.account_name}` + (account.client_account_name ? ` (Nickname: ${account.client_account_name})`: '')}
                            subtitle={getSubtitle(account)}
                            avatar={"true"}
                            disabled={account.status === 'blocked'}
                            customClass={cx(styles.item, styles.subItem)}
                            avatarClass={cx(styles.avatar, account.status === 'blocked' && styles.avatarDisable)}
                            actions={[
                                <div className={styles.actions}>
                                    <div className={cx(styles.accountBalance)}>
                                        <NumberFormat prefix={currencySymbol} value={Number.parseFloat(account.balance).toFixed(2)} thousandSeparator={true} displayType={'text'} decimalScale={2}/>
                                    </div>
                                    <div className={cx(styles.actionsStatus)}>
                                        <CustomTooltip info={'Account Status: ' + toCapitalize(account.status)}>
                                           <Icon name={getAccountStatusIcon(account.status)} width="20px" />
                                        </CustomTooltip>
                                    </div>
                                    <div
                                        className={cx(styles.seeTransaction)}
                                        onClick={() =>  navigate({
                                            pathname: `/dashboard/sub-accounts/${account.id}/transactions`,
                                            search: `?from_date=${moment().subtract(7, 'd').format('YYYY-MM-DD')}&to_date=${moment().format('YYYY-MM-DD')}`
                                        })}
                                    >
                                        <CustomTooltip info={'View Transactions'}>
                                           <Icon name={'transactions'} width="20px" />
                                        </CustomTooltip>
                                    </div>
                                    <div className={cx(styles.actionsSendFund)}
                                        disabled={!belongsTo(userTypes, AllowedUsers.SendFunds) || account.status !== 'active'}
                                        onClick={() =>  navigate({
                                            pathname: `/dashboard/funds`,
                                            search: `?from=${account.id}`
                                        })}>
                                        <CustomTooltip info={'Send funds'}>
                                            <Icon name={'send'} width="20px" />
                                        </CustomTooltip>
                                    </div>
                                    <div className={cx(styles.actionsCreatePaymentRequest)}
                                        disabled={!belongsTo(userTypes, AllowedUsers.MakePaymentRequest) || account.status !== 'active'}
                                        onClick={() =>  navigate({
                                            pathname: `/dashboard/payment-request`,
                                            search: `?from=${account.id}`
                                        })}>
                                        <CustomTooltip info={'Create payments'}>
                                            <Icon name={'payment-request'} width="20px" />
                                        </CustomTooltip>
                                    </div>
                                </div>
                            ]}
                        />
                    </React.Fragment>
                ))}
            </List>
        </Grid>
    );
};
export default AccountList;