import React, {useCallback} from "react";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from '@mui/material/TextField';

import {toCapitalize} from "../../utils/helpers";
import Icon from "./Icon";

import styles from "../../assets/styles/common.module.scss";
import cx from 'classnames';
import InfiniteScroll from "react-infinite-scroll-component";

const AutocompleteField = (props) => {
    const {
        options, required, label, clear,
        disable, onChange:parentOnChange,
        // onInputChange, className,
        name, defaultValue = '', onDetailsClick,
        error, helperText, placeholder,
        loading, open, onSelect, getData
    } = props;


    const onChange = useCallback((event) => {
        parentOnChange(event.target.value);
    }, [parentOnChange])

    const endAdornment = {
        endAdornment: <InputAdornment position={"end"} style={{position:'absolute', right: '15px'}}>
            {/*{loading ? <Icon name={'loading'} width={40} height={40}/>: null}*/}
            {defaultValue && <Icon name={'close'} onClick={() =>  clear()}/>}
        </InputAdornment>}

    const handleSelect = useCallback((item) => {
        const title = toCapitalize(item.title);
        parentOnChange(title);
        onSelect(item);
    }, [parentOnChange, onSelect])


    const fetchMoreData = useCallback(() => {
        getData()
    }, [getData]);

    return (
        <div>
            <div className={cx(disable ? styles.disable : styles.label )}>
                {label}
                {required && <span className={cx(styles.asterisk)}> *</span>}
            </div>
            <TextField
                name={name}
                fullWidth
                variant="outlined"
                id={`outlined-basic_${name}`}
                className={cx(styles.inputField, error && styles.error)}
                disabled={disable}
                placeholder={placeholder}
                value={defaultValue || ''}
                error={!!error}
                // onBlur={onBlur}
                helperText={helperText ? helperText : error ?  error : ''}
                InputProps={endAdornment}
                onChange={onChange}
            />
            {open && defaultValue?.length >= 4 && <div>
                <div className={cx(styles.autocompleteMenuList)} id="scrollableDiv"  >
                    <InfiniteScroll
                        dataLength={options.length}
                        next={fetchMoreData}
                        hasMore={true}
                        loader={loading && <div/>}
                        scrollableTarget="scrollableDiv"
                    >
                        {options.length > 0 ? options.map(option =>
                            <div
                                key={Math.random()}
                                 className={cx(styles.autocompleteMenuItem )}
                                 onClick={() => handleSelect(option)}
                            >
                                <div className={cx(styles.menuItemTitle)}>
                                    <p>{toCapitalize(option.title)}</p>
                                    <span >{option.company_number}</span>
                                </div>
                                <p className={cx(styles.menuItemSnippet)}>{toCapitalize(option.snippet)}</p>
                            </div>
                        ): loading
                            ? <p className={cx(styles.noData)}>Loading ...</p>
                            : <p className={cx(styles.noData)}>
                               The company you are searching is not registered in Company house.
                                You can still enter your data manually by clicking the link below.
                            </p>
                        }
                    </InfiniteScroll>
                </div>
                {defaultValue?.length >= 4 && <div className={cx(styles.details)}>
                    <p>Can’t find your business? <button type={'button'} onClick={() => onDetailsClick(true)}>Enter details</button></p>
                </div>}
            </div>}
        </div>
    );
}

export default AutocompleteField;
