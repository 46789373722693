import React, {useCallback, useState} from 'react';
import {actions} from "./slice";
import {useDispatch, useSelector} from "react-redux";
import selectState from "./selectors";
import {Grid} from "@mui/material";
import cx from "classnames";
import PendingPaymentsFilter from './PendingPaymentsFilter';
import styles from "./payments.module.scss";
import {Loading, Pagination} from "../../common";
import PendingPaymentsList from "./PendingPaymentsList";
import {getCompanyData} from "store/selectors";

const  PendingPayments = () => {
    const {pendingPayments, loading} = selectState();
    const dispatch = useDispatch();
    const companyData = useSelector(getCompanyData);
    const [params, setParams] = useState('');

    const handleFilter = useCallback((queryParams='', page=1, size=10) => {
        setParams(queryParams);
        dispatch(actions.getPendingPayments({page, size, queryParams}));
    }, [dispatch]);

    const refreshPayments = () => {
        dispatch(actions.getPendingPayments(1, 10, ''));
    }
    
    const handlePerPageChange = useCallback(( value) => {
        handleFilter(params, 1, value);
    }, [handleFilter, params]);

    const handlePageChange = useCallback((page, perPage) => {
        handleFilter(params, page, perPage);
    }, [handleFilter, params]);

    return (
        <Grid container className={cx(styles.container)}>
            <Grid md={12} item style={{width: '100%'}}>
                <h3 className={cx(styles.legalName)}>{`${companyData?.data?.legal_name || ''}`}</h3>
            </Grid>
            <h1 className={cx(styles.title)}>Pending payments</h1>
            <Grid container>
                <PendingPaymentsFilter onFilter={handleFilter} loading={false}/>
                {loading && <Loading className={cx(styles.loading)}/>}
                {!loading && pendingPayments?.total === 0 && <h3 className={cx(styles.noData)}>No data found</h3>}
                {!loading && pendingPayments?.items?.length > 0 && <PendingPaymentsList pendingPayments={pendingPayments.items} refreshHandler={refreshPayments}/>}
                {!loading && pendingPayments?.items?.length > 0 && <Pagination
                    data={{ items: pendingPayments?.items, page: pendingPayments?.page, size: pendingPayments?.size, total: pendingPayments?.total}}
                    pageChange={handlePageChange}
                    perPageChange={handlePerPageChange}
                />}
            </Grid>
        </Grid>
    );
};

export default PendingPayments;