import React, {useCallback, useState, useEffect} from 'react';
import AccountList from "./AccountList";
import selectAccountsState from '../../selectors';
import cx from "classnames";
import styles from "../../../transactions/transaction.module.scss";
import {Button, Icon, InputField, SelectField} from "../../../../common";
import Pagination from "../../../../common/Pagination";
import {useDispatch, useSelector} from "react-redux";
import {actions} from "../../slice";
import {accountStatuses} from "../../mockData";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {Avatar, Grid, List, ListItem, ListItemAvatar, ListItemText} from "@mui/material";
import Skeleton from '@mui/material/Skeleton';
import {getCompanyData} from "store/selectors";


const SubAccounts = () => {
    const dispatch = useDispatch();
    const {loading, subAccounts} = selectAccountsState();
    const [search, setSearch] = useState('');
    const [status, setStatus] = useState('all')
    const [searchParams] = useSearchParams();
    const location = useLocation();
    const navigate = useNavigate();
    const companyData = useSelector(getCompanyData);

    const handleFilter = useCallback((page=1, size=10) => {
        dispatch(actions.getAccounts({type: 'customer_account', page, size, status: searchParams.get('status'), search: searchParams.get('search') }));
    }, [dispatch, searchParams]);

    useEffect(() => {
        handleFilter();
    }, [handleFilter]);

    const handlePerPageChange = useCallback(( value) => {
        handleFilter(1, value);
    }, [handleFilter]);

    const handlePageChange= useCallback((page, perPage) => {
        handleFilter(page, perPage);
    }, [handleFilter]);

    const handleClear = useCallback(() => {
        setSearch('');
        if(searchParams.has('search')){
            searchParams.delete('search');
            navigate({
                pathname: location.pathname,
                search: searchParams.toString().toLocaleLowerCase()
            });
        }
    }, [location, navigate, searchParams]);

    const handleSelect = useCallback((name, value) => {
        setStatus(value);
    },[]);

    const handleSearch = useCallback(() => {
        if(status){
            status === 'all' ? searchParams.delete('status') : searchParams.set('status', status);
        }
        if(search) {
            searchParams.set('search', search);
        }
        navigate({
            pathname: location.pathname,
            search: searchParams.toString().toLocaleLowerCase()
        });
        handleFilter()
    }, [status, handleFilter, search, searchParams, navigate, location]);

    useEffect(() => {
        if(searchParams.get('status')){
            setStatus(searchParams.get('status'));
        }
        if(searchParams.get('search')){
            setSearch(searchParams.get('search'));
        }
    }, [searchParams]);

    const handleChange = useCallback((event) => {
        setSearch(event.target.value);
    }, []);

    return (
        <Grid container className={cx(styles.container)}>
            <Grid md={12} item style={{width: '100%'}}>
                <h3 className={cx(styles.legalName)}>{`${companyData?.data?.legal_name || ''}`}</h3>
            </Grid>
            <h1 className={cx(styles.title)}>Sub accounts</h1>
            <Grid container>
                <Grid item sm={12} className={styles.subFilter} sx={{marginTop: '32px'}}>
                    <div style={{position: 'relative'}}>
                        <InputField
                            label={'Account:'}
                            placeholder="Search account name or number..."
                            hasIcon
                            fullWidth
                            iconName="search"
                            iconPosition="start"
                            value={search}
                            className={'search-accounts'}
                            onChange={handleChange}
                        />
                        {search && <Icon name={'close'} className={cx(styles.accountsClear)} onClick={handleClear} />}
                    </div>
                    <div className={styles.status}>
                        <SelectField
                            name={'status'}
                            label={'Status:'}
                            options={accountStatuses}
                            onChange={handleSelect}
                            value={status}
                            className={'statusField'}
                        />
                        <Button
                            text="Search"
                            className={cx(styles.accountSearch)}
                            variant="outlined"
                            size="sm"
                            onClick={handleSearch}
                        />
                    </div>
                </Grid>
            </Grid>
            <Grid container>
                {loading &&
                    <div style={{width: '100%', marginTop: '56px'}}>
                        <List>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                        <Skeleton variant="circular" width={40} height={40} />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText>
                                    <Skeleton  variant="text" />
                                    <Skeleton  variant="text" />
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                        <Skeleton variant="circular" width={40} height={40} />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText>
                                    <Skeleton  variant="text" />
                                    <Skeleton  variant="text" />
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                        <Skeleton variant="circular" width={40} height={40} />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText>
                                    <Skeleton  variant="text" />
                                    <Skeleton  variant="text" />
                                </ListItemText>
                            </ListItem>
                        </List>
                    </div>
                }
                {!loading && subAccounts?.total === 0 && <h3 className={cx(styles.noData)}>No data found</h3>}
                {!loading && <AccountList accounts={subAccounts?.items}/>}
                {!loading && <Pagination
                    data={subAccounts}
                    pageChange={handlePageChange}
                    perPageChange={handlePerPageChange}
                />}
            </Grid>
        </Grid>
    );
};

export default SubAccounts;