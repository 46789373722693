import { createSlice } from '@reduxjs/toolkit'

/*
 *  @desc The payments slice manages the state of the pending payments
 *  slice
 *  reducer
 *  actions
 */

export const initialState = {
    loading: false,
    error: {
        status: false,
        message: ''
    },
    pendingPayments: {
        items: [],
        total: null,
        page: null,
        size: null
    },
    approvePaymentOtpError: {
        status: 200,
        message: ''
    },
    approvePaymentOtpCount: 0,
    approvePaymentOtpStatus: null,
    approvePaymentOtpToken: null,
    approvePaymentStatus: null,
    rejectPendingPaymentStatus: {
        status: false,
        message: ''
    }
}

export const pendingPaymentsSlice = createSlice({
    name: 'payments',
    initialState,
    reducers: {
        getPendingPayments(state) {
            state.loading = true;
            state.error = {
                status: false,
            };
        },
        getPendingPaymentsSuccess(state, action) {
            state.loading = false;
            state.pendingPayments = action.payload;
            state.error = {
                status: false,
            };
        },
        getPendingPaymentsError(state, action) {
            state.loading = false;
            state.error = {
                message: action.payload,
                status: true
            };
        },

        clearApprovalData: (state) => {
            state.approvePaymentOtpStatus = false;
            state.approvePaymentOtpToken = null;
            state.approvePaymentStatus = null;
            state.error = {};
            state.approvePaymentOtpError = {};
            state.loading = false;
            state.approvePaymentOtpCount = 0;    
        },
        clearState: (state) => {
            state.pendingPayments = {
                items: [],
                total: null,
                page: null,
                size: null
            };
            state.approvePaymentOtpStatus = false;
            state.approvePaymentOtpToken = null;
            state.approvePaymentStatus = null;
            state.error = {};
            state.approvePaymentOtpError = {};
            state.loading = false;
            state.approvePaymentOtpCount = 0;    
        },
        getApprovePaymentOtp(state, action) {
            state.approvePaymentOtpError = {
                status: 200,
                message: ''
            }
        },
        getApprovePaymentOtpSuccess: (state, action) => {
            state.approvePaymentOtpStatus = 'success';
            state.approvePaymentOtpToken = action.payload?.token;
            state.loading = false;
        },
        getApprovePaymentOtpError: (state, action) => {
            state.approvePaymentOtpStatus = 'failed';
            state.approvePaymentOtpToken = null;
            state.approvePaymentOtpError = {...action.payload}
            state.loading = false;
        },
        approvePendingPayments(state) {
            state.approvePaymentOtpError = {
                status: false,
                message: '',
            };
        },
        approvePendingPaymentsSuccess(state, action) {
            state.approvePaymentStatus = 'success';
            state.approvePaymentOtpError = {
                status: false,
                message: '',
            };
        },
        approvePendingPaymentsError(state, action) {
            state.error = {
                message: action.payload,
                status: true
            };
            state.approvePaymentOtpStatus = 'failed';
            state.approvePaymentOtpError = {...action.payload};
            state.approvePaymentOtpCount = state.approvePaymentOtpCount+1;
        },

        clearRejectData: (state) => {
            state.error = {};
            state.rejectPendingPaymentStatus = null;
            state.loading = false;
        },

        rejectPendingPayments(state) {
            state.loading = true;
            state.error = {
                status: false,
            };
        },
        rejectPendingPaymentsSuccess(state, action) {
            state.loading = false;
            state.rejectPendingPaymentStatus = 'success';
            state.error = {
                status: false,
            };
        },
        rejectPendingPaymentsError(state, action) {
            state.loading = false;
            state.rejectPendingPaymentStatus = 'failed';
            state.error = {
                message: action.payload,
                status: true
            };
        },
    }
})

export const { actions, reducer, name } = pendingPaymentsSlice;
