import React, { useEffect, useState } from "react";
import cx from "classnames";
import Icon from "../Icon";
import { useLocation, useNavigate} from "react-router-dom";
import styles from "./sidemenu.module.scss";
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Collapse from '@mui/material/Collapse';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import styled from 'styled-components';
import useMediaQuery from "@mui/material/useMediaQuery";
import { Stack, Switch, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getCurrencyData } from "store/selectors/currency";
import { changeCurrencyType } from "store/actions";

const drawerWidth = 260;

const StyledDrawer = styled(Drawer)(({ $toggle,variant }) => ({
  width: `${drawerWidth}px`,
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  border: '1px solid #E5E5E5',
    borderRadius: '0px 15px 15px 0px',
  position: `${variant === 'temporary' ? 'fixed' : 'relative'}`,
  '.MuiBackdrop-root': {
      backgroundColor: `${$toggle ? 'rgba(255,255,255, 0.8)' : 'unset'}`
  },
  '.MuiDrawer-paper': {
        border: 'none',
        padding: '70px 20px',
        position: 'relative',
      filter: 'drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.4))',
      borderRadius: '0px 15px 15px 0px',
  },
  '.MuiListItemButton-root':  {
    borderRadius: '10px',
    padding: '5px 10px',
    marginBottom: '5px',
    'svg': {
      color: '#111326',
      fill: '#111326',
      'path': {
          fill: '#111326',
      }
    },
    '&:hover': {
      background: 'rgba(0, 160, 155, 0.19)',
      color: '#00A09B',
      'svg': {
        color: '#00A09B',
        fill: '#00A09B',
        'path': {
          fill: '#00A09B',
        }
      }
    },
   ' &.Mui-selected': {
      background: 'rgba(0, 160, 155, 0.19)',
      color: '#00A09B',
      
      'svg': {
        color: '#00A09B',
        fill: '#00A09B',
        'path': {
          fill: '#00A09B',
        }
      },
      
      '&:hover': {
        background: 'rgba(0, 160, 155, 0.19)',
      },
      '.MuiListItemText-primary': {
        fontWeight: 'bold',
      }
    },
  },
    '.MuiCollapse-wrapper': {
      '.MuiListItemButton-root': {
          marginBottom: 0,
          padding: '2px',
          '&:hover': {
              background: 'transparent',
          },
      },
        '.Mui-selected': {
            backgroundColor: 'transparent',
            color: '#00A09B',
            '.MuiTypography-root': {
                fontWeight: 500,
            },
            '&:hover': {
                background: 'transparent',
            },
        },
    },
}));

const StyledLogo = styled.div`
    margin: 10px auto 30px auto;
`;

const StyledListText = styled(ListItemText)`
    margin-left: -20px;
`;

const StyledIconListItem = styled(ListItemIcon)`
    padding-left: 15px;
`;

const StyledFooterMenu = styled.div`
  margin-top: auto;
`;

const switchStyle = {
    borderRadius: 2,
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "rgb(0, 160, 155)",
    },
    "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
      backgroundColor: 'rgba(0, 160, 155, 0.39)',
    },
    "& .MuiSwitch-track": {
        backgroundColor: 'rgba(0, 160, 155, 0.39)',
    },    
    "&:hover .MuiSwitch-switchBase": {
      color: 'rgb(0, 160, 155)',
    },
    ".MuiSwitch-switchBase": {
        color: 'rgb(0, 160, 155)',
    },
  };

const SideMenu = ({
    menuItems,
    handleNavigate,
    toggle,
    openSubMenu,
    footerMenu,
    menuDisabled,
    featureFlags,
    handleToggleClick,
 }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const currency = useSelector(getCurrencyData);
    const [switchStatus, setSwitchStatus] = useState(false);
    const location = useLocation();
    let window;
    const isMobile = useMediaQuery('(max-width:900px)');

    const container = window !== undefined ? () => window().document.body : undefined;

    useEffect(() => {
        setSwitchStatus(currency.currency !== 'GBP');
    }, [currency]);

    const listItems = (
        <List>
            {menuItems.filter(item => item.hide !== true)
                .map((menu, index) => (
                <React.Fragment key={index}>
                    <ListItemButton
                        button={"true"}
                        key={menu.id}
                        selected={location.pathname.includes(menu.path.toLowerCase())}
                        onClick={() => {
                            handleNavigate(menu.path, !!menu?.children?.length);
                            if(isMobile) {
                                handleToggleClick();
                            }
                        }}
                        disabled={!menuDisabled || menu.disabled}
                    >
                        <StyledIconListItem>
                            <Icon name={menu.icon} className={cx(styles.menuItemsIcon)} />
                        </StyledIconListItem>
                        <StyledListText primary={menu.title} />
                        {menu.children.length > 0 && <Icon name={openSubMenu ? 'caret-up' : 'caret-down'} className={cx(styles.caretDown)}/>}
                    </ListItemButton>
                </React.Fragment>
            ))}
        </List>
    )

    const footerItems = (
        <StyledFooterMenu>
            <List>
                {footerMenu
                    .map((menu, index) => (
                    <React.Fragment key={menu.path}>
                        <ListItemButton button={"true"} key={index} onClick={() => {
                        handleNavigate(menu.path, !!menu?.children?.length);
                            if(isMobile) {
                                handleToggleClick();
                            }

                        }}
                            disabled={menu.disabled}
                            id={menu.icon}
                        >
                            <StyledIconListItem>
                                <Icon name={menu.icon} className={cx(styles.menuItemsIcon)} />
                            </StyledIconListItem>
                            <StyledListText primary={menu.title} />
                            {menu?.children?.length > 0 && <Icon name={openSubMenu ? 'caret-up' : 'caret-down'} className={cx(styles.caretDown)}/>}
                        </ListItemButton>
                        <Collapse in={openSubMenu} key={menu.icon} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding key={index}>
                            {menu?.children?.length > 0 && (openSubMenu) && menu.children?.map(subMenu =>
                                <ListItemButton
                                    button={"true"}
                                    key={subMenu.id}
                                    onClick={() => navigate(subMenu.path)}
                                    disabled={!menuDisabled || subMenu.disabled}
                                    selected={location.pathname === subMenu.path.toLowerCase()}
                                >
                                        <StyledIconListItem sx={{ pl: 4 }}>
                                            <Icon name={subMenu.icon}/>
                                        </StyledIconListItem>
                                        <StyledListText primary={subMenu.title} />
                                </ListItemButton>
                            )}
                            </List>
                        </Collapse>
                    </React.Fragment>
                ))}
            </List>
        </StyledFooterMenu>
    )

    return (
        <StyledDrawer
            container={container}
            anchor="left"
            variant={`${isMobile ? "temporary" : "permanent"}`}
            open={isMobile ? toggle : true}
            sx={{
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
        >
            <StyledLogo>
                {toggle && <Icon name={'menu'} onClick={handleToggleClick} style={{marginRight: '20px'}}/>}
                <Icon name={'logo'} className={styles.logoIcon}/>
            </StyledLogo>
            {featureFlags.eur_accounts_enabled && 
                <Stack direction="row" spacing={1} alignItems="center" style= {{ "margin": "5px auto"}}>
                    <Icon name={'gb'} width="20px" /><Typography>GBP</Typography>
                    <Switch
                        sx={switchStyle}
                        onChange={event => {
                            const selectedCurrency = event.target.checked ? 'EUR' : 'GBP';
                            dispatch(changeCurrencyType(selectedCurrency));
                        }} 
                        checked={switchStatus}/>
                    <Typography>EUR</Typography><Icon name={'eur'} width="20px" />
                </Stack>}
            {listItems}
            {footerItems}
        </StyledDrawer>
    );
}
export default SideMenu;
