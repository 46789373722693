import React,  {useCallback, useEffect, useRef, useState} from 'react';
import {Grid} from "@mui/material";
import cx from "classnames";
import styles from 'assets/styles/profile.module.scss';
import {useDispatch, useSelector} from "react-redux";
import {getCompanyData, getUserData} from "store/selectors";
import { Button, Dialog, PasswordStrength, InputField } from 'components/common';
import {getUserTypes} from "store/selectors/auth"
import {useFormik} from "formik";
import * as yup from "yup";
import selectState from "./selectors";
import {default as appSelectState} from "AppSelectors";

import {
    logout
} from "store/actions";
import { actions } from './slice';

const Profile = () => {
    const companyData = useSelector(getCompanyData);
    const dispatch = useDispatch();
    const {data} = useSelector(getUserData);
    const userTypes = useSelector(getUserTypes);
    const [showChangePwd, setShowChangePwd] = useState(false);
    const [toggleIcon, setToggleIcon] = useState('show-password')
    const [toggleConfirmIcon, setToggleConfirmIcon] = useState('show-password')
    const [toggleCurrentIcon, setToggleCurrentIcon] = useState('show-password')
    const [errorMessage, setErrorMessage] = useState('');
    const {loading, error, changePassword: {status}} = selectState();
    const {featureFlags} = appSelectState();
    const [isPwdConfirmationModalOpen, setIsPwdConfirmationModalOpen] = React.useState(false);
    const [timer, setTimer] = useState('0');
    // We need ref in this, because we are dealing
    // with JS setInterval to keep track of it and
    // stop it when needed
    const Ref = useRef(null);

    const validationSchema = yup.object({
        currentPassword: yup
            .string('Enter your current password')
            .required('Current Password is required'),
        password: yup
            .string('Enter your password')
            .required('Password is required')
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
                "Password should contain at least 8 characters with 1 upper case letter and 1 number"
            ).test("is-not-current-password", "New password can't be same as existing password", function(password) {
                const { currentPassword } = this.parent;
                return password !== currentPassword;
              }),
        confirmPassword: yup
            .string('Confirm your password')
            .oneOf([yup.ref('password'), null], 'Passwords must match')
            .required('Confirm password is required')
    });

    const getTimeRemaining = useCallback((e) => {
        const total = Date.parse(e) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        return {
            total, seconds
        };
    }, []);

    const endTime = useCallback(() => {
        let time = new Date();
 
        // This is where you need to adjust if 
        // you entend to add more time
        time.setSeconds(time.getSeconds() + 9);
        return time;
    }, []);

    const refreshTimer = useCallback((e) => {
        let { total, seconds } = getTimeRemaining(e);
        if (total > 0) {
            setTimer( '' + seconds);
        } else if (total <= 0) {
            clearInterval(Ref.current);
            dispatch(logout());
        }
    }, [dispatch, getTimeRemaining, setTimer]);

    const startTimer = useCallback((e) => {
        // If you adjust it you should also need to adjust the Endtime formula we are about to code next.
        setTimer('9');
 
        // Clearing the previous setInterval if the timer is started again
        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
            refreshTimer(e);
        }, 1000)
        Ref.current = id;
    }, [setTimer, refreshTimer]);
 
    const formik = useFormik({
        initialValues: {
            currentPassword: '',
            password: '',
            confirmPassword: ''
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            dispatch(actions.changePassword(values));
        },
    });

    useEffect(() => {
        if (!status) {
            return;
        }
        if (status === 'success') {
            startTimer(endTime());
            setIsPwdConfirmationModalOpen(true);            
        }
    }, [dispatch, status, startTimer, endTime]);

    const handleChange = useCallback((event) => {
        formik.setValues(values => ({...values, [event.target.name]: event.target.value }))
        setErrorMessage('')
    }, [formik]);

    const toggleShowCurrentPassword = useCallback(() => {
        setToggleCurrentIcon(toggleCurrentIcon === 'show-password' ? 'hide-password': 'show-password')
    }, [toggleCurrentIcon]);

    const toggleShowPassword = useCallback(() => {
        setToggleIcon(toggleIcon === 'show-password' ? 'hide-password': 'show-password')
    }, [toggleIcon]);

    const toggleShowConfirmPassword = useCallback(() => {
        setToggleConfirmIcon(toggleConfirmIcon === 'show-password' ? 'hide-password': 'show-password')
    }, [toggleConfirmIcon]);

    const clearChangePassword = () => {
        dispatch(actions.clearState());
        formik.resetForm();
        setShowChangePwd(false);
    }

    return (
        <Grid container className={cx(styles.container)}>
            <Grid md={12} item style={{width: '100%'}}>
                <h3 className={cx(styles.legalName)}>{`${companyData?.data?.legal_name || ''}`}</h3>
            </Grid>
            <Grid md={6} item>
                <h1 className={cx(styles.title)}>{'Profile'}</h1>
            </Grid>
            <div className={cx(styles.details)}>
                <div className={cx(styles.datarow)}>
                    <div className={cx(styles.header)}>
                        User
                    </div>
                    <div className={cx(styles.value)}>
                        {`${data?.first_name || ''} ${data?.last_name || ''}`}
                    </div>
                </div>
                {featureFlags.maker_approver_enabled && <div className={cx(styles.datarow)}>
                    <div className={cx(styles.header)}>
                        Roles
                    </div>
                    <div>
                        {userTypes.join(', ')}
                    </div>
                </div>}
            </div>
            <div className={cx(styles.data)}>
                <div className={cx(styles.datacol)}>
                    <Button
                        text="Change Password"
                        className = {cx(styles.changePasswordBtn)}
                        disabled={showChangePwd}
                        onClick={() => {
                            setShowChangePwd(true);
                        }}
                    />
                </div>
            </div>
            {showChangePwd && error?.message && <div className={cx(styles.error)}>{error.message}</div>}
            {showChangePwd &&
                <Grid container className={cx(styles.changePassword)}>
                    <form onSubmit={formik.handleSubmit} className={cx(styles.form)}>
                        <InputField
                            type={toggleCurrentIcon === 'show-password' ? 'password' : 'text'}
                            name={'currentPassword'}
                            label={'Type the current password'}
                            required={true}
                            disable={loading}
                            value={formik.values.currentPassword}
                            onChange={handleChange}
                            handleIconClick={toggleShowCurrentPassword}
                            hasIcon={true}
                            iconPosition="end"
                            iconName={toggleCurrentIcon}
                            error={(formik.touched.currentPassword && Boolean(formik.errors.currentPassword)) || errorMessage}
                            helperText={formik.touched.currentPassword && formik.errors.currentPassword}
                        />
                        <InputField
                            type={toggleIcon === 'show-password' ? 'password' : 'text'}
                            name={'password'}
                            label={'Create a new password'}
                            required={true}
                            disable={loading}
                            value={formik.values.password}
                            onChange={handleChange}
                            handleIconClick={toggleShowPassword}
                            hasIcon={true}
                            iconPosition="end"
                            iconName={toggleIcon}
                            error={(formik.touched.password && Boolean(formik.errors.password)) || errorMessage}
                            helperText={formik.touched.password && formik.errors.password}
                        />
                        {formik.values.password && 
                            <div className={cx(styles.pwdStrengh)}>
                                Password Strength: 
                                <PasswordStrength password={formik.values.password}/>
                            </div>}
                        <InputField
                            type={toggleConfirmIcon === 'show-password' ? 'password' : 'text'}
                            name={'confirmPassword'}
                            label={'Re-type the new password'}
                            required={true}
                            disable={loading}
                            value={formik.values.confirmPassword}
                            onChange={handleChange}
                            handleIconClick={toggleShowConfirmPassword}
                            iconName={toggleConfirmIcon}
                            hasIcon={true}
                            iconPosition="end"
                            error={(formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)) || errorMessage}
                            helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                        />
                        <div>
                            <button className={cx(styles.cancelBtn)} type="button" onClick={() => {
                                clearChangePassword();
                            }}>
                                <p>Cancel</p>
                            </button>
                            <button disabled={loading} className={cx(styles.submitBtn)} type="submit">
                                <p>Save changes</p>
                            </button>
                        </div>
                    </form>
                </Grid>
            }
            <Dialog
                fullWidth
                open={isPwdConfirmationModalOpen}
                isSingleButton={false}
                className={'deactivate-key-modal'}
                setOpen={() => {
                    setIsPwdConfirmationModalOpen(false);
                }}
                submitButton={null}
                cancelButton={null}
                backdropProps={{
                    style: {
                        backgroundColor: 'rgba(255,255,255, 0.8)'
                    }
                }}
            >
                <div className={cx(styles.confirmationDialog)}>
                    <div className={cx(styles.confirmation)}>
                        Your password has been changed successfully. You will be redirected to the login page in 
                        <span className={cx(styles.otp)}> {timer} </span>
                        seconds. Use your new password to log in.
                    </div>
                </div>
            </Dialog>

        </Grid>
        
    );
};

export default Profile;