import React, {useCallback, useEffect, useState} from 'react';
import {Grid} from "@mui/material";
import cx from "classnames";
import styles from "../../account.module.scss";
import {Icon} from "../../../../common";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {actions} from "../../slice";
import {useDispatch} from "react-redux";
import selectState from "../../selectors";
import Skeleton from "@mui/material/Skeleton";
import { EURAccount, GBPAccount, StyledAccountInfo } from 'components/dashboard/Dashboard';
import NumberFormat from 'react-number-format';

const AccountInfo = ({accountId, onRefresh}) => {
    const dispatch = useDispatch();
    const {selectedAccount, loading} = selectState();

    const [balanceMessage, setBalanceMessage] = useState('');
    const [clipboardContent, setClipboardContent] = useState({
        value:  '',
        copied: false,
    });


    useEffect(() => {
        const timer = () => setTimeout(() => setClipboardContent(val => ({...val, copied: false})), 4000);
        const timerId = timer();
        return () => {
            clearTimeout(timerId);
        };
    }, [clipboardContent.copied]);

    const handleRefresh = useCallback(() => {
        if(!loading) {
            dispatch(actions.getSelectedAccount({accountId}));
            onRefresh();
        }
    }, [dispatch, loading, accountId, onRefresh]);

    useEffect(() => {
        dispatch(actions.getSelectedAccount({accountId}));
    }, [dispatch, accountId])

    useEffect(() => {
        if(selectedAccount === null) {
            return;
        }
        switch(selectedAccount?.currency) {
            case 'GBP':
                setClipboardContent(val => ({...val, value: `Account number: ${selectedAccount?.account_number || 'N/A'} Sort code: ${selectedAccount?.sort_code || 'N/A'}`}));
                setBalanceMessage(`The GBP balance as of now for account ${selectedAccount?.account_number || '-'}`);
                return;
            case 'EUR':
                setClipboardContent(val => ({...val, value: `IBAN: ${selectedAccount?.iban || 'N/A'} BIC: ${selectedAccount?.bic || 'N/A'}`}));
                setBalanceMessage(`The EUR balance as of now for IBAN ${selectedAccount?.iban || '-'}`);
                return;
            default:
        }
    }, [selectedAccount]);

    const getIcon = () => {
        if (selectedAccount === null) {
            return '';
        }
        return selectedAccount?.currency === 'GBP' ? 'gb': 'eur';
    }

    const getCurrencySymbol = () => {
        if (selectedAccount === null) {
            return '';
        }
        return selectedAccount?.currency === 'GBP' ? '£' : '€';
    }

    return (
        <Grid container className={cx(styles.accountInfo)}>
            <Grid item sm={7} className={cx(styles.balance)}>
                <div className={styles.refresh}>
                    <h3>Total balance</h3>
                    <Icon name={'refresh'} onClick={handleRefresh}/>
                </div>
                {selectedAccount
                    ? <h2>{getCurrencySymbol()}<NumberFormat value={selectedAccount?.balance} thousandSeparator={true} displayType={'text'} decimalScale={2}/></h2>
                    : loading
                        ? <h2><Skeleton animation={"pulse"} width={'50%'} height={'100%'}/></h2>
                        : '0.00'
                }
                <h5><Icon name={getIcon()} width="20px"/> {balanceMessage} </h5>
            </Grid>
            <Grid item sm={5} className={cx(styles.copy)}>
                <StyledAccountInfo style={{'height': '60px', 'float': 'right'}}>
                    <div>
                        <CopyToClipboard text={clipboardContent.value}>
                            <Icon name={'colored-copy'} 
                                style={{float: 'right'}} 
                                onClick={() => setClipboardContent(val => ({...val, copied: true}))} 
                                className={cx(styles.copyIcon)}/>
                        </CopyToClipboard>

                        { selectedAccount?.currency === 'GBP' && <GBPAccount accounts={[selectedAccount]}/>}
                        { selectedAccount?.currency === 'EUR' && <EURAccount accounts={[selectedAccount]}/>}
                        {clipboardContent.copied && <span className={cx(styles.copied)}>Copied!</span>}
                    </div>
                </StyledAccountInfo>
            </Grid>
        </Grid>
    );
};

export default AccountInfo;