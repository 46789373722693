import jwt from 'jwt-decode';
import store from 'store';


const initialState = {
    login: {
        isOtpVerified: false,
        user_id: '',
        company_id: '',
    },
    signup: {},
    isLoading: false,
    loginError: null,
    regError: null
}

export const Roles = Object.freeze({
    ExternalUser: 'external_user',
    BasicUser: 'basic_user',
    Developer: 'developer',
    ViewerAll: 'viewer_all',
    MakerAll: 'maker_all',
    ApproverAll: 'approver_all',
    InstantPayerAll: 'instant_payer_all',
})

export const UserType = Object.freeze({
    ExternalUser: 'Admin',
    Developer: 'Developer',
    BasicUser: 'Basic',
    Viewer: 'Viewer',
    Maker: 'Maker',
    Approver: 'Approver',
    InstantPayer: 'Instant Payer',
});

export const AllowedUsers = Object.freeze({
    Dashboard: [
        UserType.Viewer, UserType.Maker,
        UserType.Approver, UserType.ExternalUser,
        UserType.InstantPayer,
    ],
    Transaction: [
        UserType.Viewer, UserType.Maker,
        UserType.Approver, UserType.ExternalUser,
        UserType.InstantPayer,
    ],
    SendFunds: [
        UserType.InstantPayer,
        UserType.ExternalUser,
    ],
    MakePaymentRequest: [
        UserType.Maker,
        [UserType.Maker, UserType.Approver],
    ],
    ApprovePendingPayments: [
        UserType.Viewer,
        UserType.Maker,
        UserType.Approver,
        UserType.ExternalUser,
        UserType.InstantPayer,
    ],
    Recipient: [
        UserType.Viewer, UserType.Maker,
        UserType.Approver, UserType.ExternalUser,
        UserType.InstantPayer,
    ],
    SubAccounts: [
        UserType.Viewer, UserType.Maker,
        UserType.Approver, UserType.ExternalUser,
        UserType.InstantPayer,
    ],
    Developers:[
        UserType.Developer,
    ]
});


export const belongsTo = (userTypes, expectedTypes) => {
    return expectedTypes?.some(expectedType => {
        if(typeof expectedType === 'string'){
            return userTypes?.includes(expectedType);
        }
        if(typeof expectedType === 'object'){
            return expectedType.every((e) => userTypes?.includes(e));
        }
        return false;
    });
};

export const getJwtExpiryTime = () => {
    const state = store.getState();
    if (state) {
        const login = state.auth.login;

        if (login) {
            const { X_TOKEN } = login;
            if (X_TOKEN) {
                const nowToSeconds = Math.floor(Date.now() / 1000);
                const jwtToken = jwt(X_TOKEN);
                const {
                    exp: jwtExpire,
                } = jwtToken;

                if (
                    jwtExpire > nowToSeconds
                ) {
                    return jwtExpire - nowToSeconds;
                }
            }
        }
    }
    return 0;
};

export const isLogged = () => {
    const state = store.getState();
    if (state) {
        const login = state.auth.login;

        if (login) {
            const { X_TOKEN } = login;
            if (X_TOKEN) {
                const nowToSeconds = Math.floor(Date.now() / 1000);
                const jwtToken = jwt(X_TOKEN);
                const {
                    exp: jwtExpire,
                } = jwtToken;

                if (
                    jwtExpire >= nowToSeconds
                ) {
                    return true;
                }
            }
        }
    }
    return false;
};

export const getOtp = () => {
    const state = store.getState();
    if (state) {
        const login = state.auth.login;

        if (login) {
            const { isOtpVerified } = login;
            if (isOtpVerified) {
                return true;
            }
        }
    }
    return false;
}

export const getAccessToken = () => {
    const state = store.getState();
    const login = state.auth.login;
    if (login) {
        const { X_TOKEN } = login;
        return X_TOKEN;
    }
};

export const getCurrentUser = () => {
    const state = store ? store.getState() : initialState;
    const login = state.auth.login;
    if (login) {
        return login;
    }
};

export const getCompany = () => {
    const state = store ? store.getState() : initialState;
    const company = state?.companyData;
    if (company) {
        return company;
    }
};

export const getOtpToken = () => {
    const state = store.getState();
    const login = state.auth.login;
    if (login) {
        const { token } = login;
        return token;
    }
};

export const getReCaptchaToken = () => {
    const state = store.getState();
    const login = state.auth.login;

    if (login) {
        const { x_recaptcha_token } = login;
        return x_recaptcha_token;
    }
}