

export const recipientsData = [
    {
        id: "63a0ef40-7a22-4e68-9612-361e5f8b4497",
        account_holder_name: "Barclays",
        summary: "Credit payment",
        account_number: "123456789",
        sort_code: "12345",
        nickname: "Barclays",
        created_at: "2022-02-16T07:39:33.780Z"
    },
    {
        id: "63a0ef40-7a22-4e68-9612-361e5f8b4498",
        account_holder_name: "Merge Money Ltd",
        summary: "GBP account ending 1823",
        account_number: "123456789",
        sort_code: "12345",
        nickname: "",
        created_at: "2022-02-16T07:39:33.780Z"
    },
    {
        id: "63a0ef40-7a22-4e68-9612-361e5f8b4499",
        account_holder_name: "Electrical Engeneering London Ltd",
        summary: "Electricity bill",
        account_number: "123456789",
        sort_code: "12345",
        nickname: "Electricity",
        created_at: "2022-02-16T07:39:33.780Z"
    },
    {
        id: "63a0ef40-7a22-4e68-9612-361e5f8b4459",
        account_holder_name: "Electrical Ltd",
        summary: "Electricity bill",
        account_number: "123456789",
        sort_code: "12345",
        nickname: "Electricity",
        created_at: "2022-02-16T07:39:33.780Z"
    },
    {
        id: "63a0ef40-7a22-4e68-9612-361e5f8b4439",
        account_holder_name: "Electrical London Ltd",
        summary: "Electricity bill",
        account_number: "123456789",
        sort_code: "12345",
        nickname: "Electricity",
        created_at: "2022-02-16T07:39:33.780Z"
    },
    {
        id: "63a0ef40-7a22-4e68s-9612-361e5f8b439",
        account_holder_name: "Electrical London Ltd",
        summary: "Electricity bill",
        account_number: "123456789",
        sort_code: "12345",
        nickname: "Electricity",
        created_at: "2022-02-16T07:39:33.780Z"
    },
    {
        id: "63a0ef40-7a22-4e68s-9612-361e5f8b479",
        account_holder_name: "London Ltd",
        summary: "Electricity bill",
        account_number: "123456789",
        sort_code: "12345",
        nickname: "Electricity",
        created_at: "2022-02-16T07:39:33.780Z"
    }
]

/**
 * @description handles the tab change
 * @param {number} event
 * @param {number} newValue
 */
export const fundsType = [
    {
        label: 'Person',
        value: 'person',
        type: 'type',
    },
    {
        label: 'Business',
        value: 'business',
        type: 'type',
    }
];

export const bankDetailsType = [
    {
        label: 'Account number',
        value: 'account',
        type: 'type',
    },
    {
        label: 'IBAN',
        value: 'iban',
        type: 'type',
    }
];

export const supportedCountries = {
  'GBP': [
    {
      "label": "Aland Island",
      "value": "AX",
      "type": "country"
    },
    {
      "label": "Andorra",
      "value": "AD",
      "type": "country"
    },
    {
      "label": "Austria",
      "value": "AT",
      "type": "country"
    },
    {
      "label": "Belgium",
      "value": "BE",
      "type": "country"
    },
    {
      "label": "Bulgaria",
      "value": "BG",
      "type": "country"
    },
    {
      "label": "Croatia",
      "value": "HR",
      "type": "country"
    },
    {
      "label": "Cyprus",
      "value": "CY",
      "type": "country"
    },
    {
      "label": "Czechia",
      "value": "CZ",
      "type": "country"
    },
    {
      "label": "Denmark",
      "value": "DK",
      "type": "country"
    },
    {
      "label": "Estonia",
      "value": "EE",
      "type": "country"
    },
    {
      "label": "Finland",
      "value": "FI",
      "type": "country"
    },
    {
      "label": "France",
      "value": "FR",
      "type": "country"
    },
    {
      "label": "French Guiana",
      "value": "GF",
      "type": "country"
    },
    {
      "label": "Germany",
      "value": "DE",
      "type": "country"
    },
    {
      "label": "Gibraltar",
      "value": "GI",
      "type": "country"
    },
    {
      "label": "Greece",
      "value": "GR",
      "type": "country"
    },
    {
      "label": "Guadeloupe",
      "value": "GP",
      "type": "country"
    },
    {
      "label": "Guernsey",
      "value": "GG",
      "type": "country"
    },
    {
      "label": "Hungary",
      "value": "HU",
      "type": "country"
    },
    {
      "label": "Iceland",
      "value": "IS",
      "type": "country"
    },
    {
      "label": "Ireland",
      "value": "IE",
      "type": "country"
    },
    {
      "label": "Isle of Man",
      "value": "IM",
      "type": "country"
    },
    {
      "label": "Italy",
      "value": "IT",
      "type": "country"
    },
    {
      "label": "Jersey",
      "value": "JE",
      "type": "country"
    },
    {
      "label": "Latvia",
      "value": "LV",
      "type": "country"
    },
    {
      "label": "Liechtenstein",
      "value": "LI",
      "type": "country"
    },
    {
      "label": "Lithuania",
      "value": "LT",
      "type": "country"
    },
    {
      "label": "Luxembourg",
      "value": "LU",
      "type": "country"
    },
    {
      "label": "Malta",
      "value": "MT",
      "type": "country"
    },
    {
      "label": "Martinique",
      "value": "MQ",
      "type": "country"
    },
    {
      "label": "Mayotte",
      "value": "YT",
      "type": "country"
    },
    {
      "label": "Monaco",
      "value": "MC",
      "type": "country"
    },
    {
      "label": "Norway",
      "value": "NO",
      "type": "country"
    },
    {
      "label": "Poland",
      "value": "PL",
      "type": "country"
    },
    {
      "label": "Portugal",
      "value": "PT",
      "type": "country"
    },
    {
      "label": "Reunion",
      "value": "RE",
      "type": "country"
    },
    {
      "label": "Romania",
      "value": "RO",
      "type": "country"
    },
    {
      "label": "Saint Barthelemy",
      "value": "BL",
      "type": "country"
    },
    {
      "label": "Saint Martin",
      "value": "MF",
      "type": "country"
    },
    {
      "label": "Saint-Pierre-et-Miquelon",
      "value": "PM",
      "type": "country"
    },
    {
      "label": "San Marino",
      "value": "SM",
      "type": "country"
    },
    {
      "label": "Slovakia",
      "value": "SK",
      "type": "country"
    },
    {
      "label": "Slovenia",
      "value": "SI",
      "type": "country"
    },
    {
      "label": "Spain",
      "value": "ES",
      "type": "country"
    },
    {
      "label": "Sweden",
      "value": "SE",
      "type": "country"
    },
    {
      "label": "Switzerland",
      "value": "CH",
      "type": "country"
    },
    {
      "label": "The Netherlands",
      "value": "NL",
      "type": "country"
    },
    {
      "label": "The United Kingdom",
      "value": "GB",
      "type": "country"
    },
    {
      "label": "Vatican City",
      "value": "VA",
      "type": "country"
    }
  ],
  'EUR': [
    {
      "label": "Aland Island",
      "value": "AX",
      "type": "country"
    },
    {
      "label": "Andorra",
      "value": "AD",
      "type": "country"
    },
    {
      "label": "Austria",
      "value": "AT",
      "type": "country"
    },
    {
      "label": "Belgium",
      "value": "BE",
      "type": "country"
    },
    {
      "label": "Bulgaria",
      "value": "BG",
      "type": "country"
    },
    {
      "label": "Croatia",
      "value": "HR",
      "type": "country"
    },
    {
      "label": "Cyprus",
      "value": "CY",
      "type": "country"
    },
    {
      "label": "Czechia",
      "value": "CZ",
      "type": "country"
    },
    {
      "label": "Denmark",
      "value": "DK",
      "type": "country"
    },
    {
      "label": "Estonia",
      "value": "EE",
      "type": "country"
    },
    {
      "label": "Finland",
      "value": "FI",
      "type": "country"
    },
    {
      "label": "France",
      "value": "FR",
      "type": "country"
    },
    {
      "label": "French Guiana",
      "value": "GF",
      "type": "country"
    },
    {
      "label": "Germany",
      "value": "DE",
      "type": "country"
    },
    {
      "label": "Gibraltar",
      "value": "GI",
      "type": "country"
    },
    {
      "label": "Greece",
      "value": "GR",
      "type": "country"
    },
    {
      "label": "Guadeloupe",
      "value": "GP",
      "type": "country"
    },
    {
      "label": "Guernsey",
      "value": "GG",
      "type": "country"
    },
    {
      "label": "Hungary",
      "value": "HU",
      "type": "country"
    },
    {
      "label": "Iceland",
      "value": "IS",
      "type": "country"
    },
    {
      "label": "Ireland",
      "value": "IE",
      "type": "country"
    },
    {
      "label": "Isle of Man",
      "value": "IM",
      "type": "country"
    },
    {
      "label": "Italy",
      "value": "IT",
      "type": "country"
    },
    {
      "label": "Jersey",
      "value": "JE",
      "type": "country"
    },
    {
      "label": "Latvia",
      "value": "LV",
      "type": "country"
    },
    {
      "label": "Liechtenstein",
      "value": "LI",
      "type": "country"
    },
    {
      "label": "Lithuania",
      "value": "LT",
      "type": "country"
    },
    {
      "label": "Luxembourg",
      "value": "LU",
      "type": "country"
    },
    {
      "label": "Malta",
      "value": "MT",
      "type": "country"
    },
    {
      "label": "Martinique",
      "value": "MQ",
      "type": "country"
    },
    {
      "label": "Mayotte",
      "value": "YT",
      "type": "country"
    },
    {
      "label": "Monaco",
      "value": "MC",
      "type": "country"
    },
    {
      "label": "Norway",
      "value": "NO",
      "type": "country"
    },
    {
      "label": "Poland",
      "value": "PL",
      "type": "country"
    },
    {
      "label": "Portugal",
      "value": "PT",
      "type": "country"
    },
    {
      "label": "Reunion",
      "value": "RE",
      "type": "country"
    },
    {
      "label": "Romania",
      "value": "RO",
      "type": "country"
    },
    {
      "label": "Saint Barthelemy",
      "value": "BL",
      "type": "country"
    },
    {
      "label": "Saint Martin",
      "value": "MF",
      "type": "country"
    },
    {
      "label": "Saint-Pierre-et-Miquelon",
      "value": "PM",
      "type": "country"
    },
    {
      "label": "San Marino",
      "value": "SM",
      "type": "country"
    },
    {
      "label": "Slovakia",
      "value": "SK",
      "type": "country"
    },
    {
      "label": "Slovenia",
      "value": "SI",
      "type": "country"
    },
    {
      "label": "Spain",
      "value": "ES",
      "type": "country"
    },
    {
      "label": "Sweden",
      "value": "SE",
      "type": "country"
    },
    {
      "label": "Switzerland",
      "value": "CH",
      "type": "country"
    },
    {
      "label": "The Netherlands",
      "value": "NL",
      "type": "country"
    },
    {
      "label": "The United Kingdom",
      "value": "GB",
      "type": "country"
    },
    {
      "label": "Vatican City",
      "value": "VA",
      "type": "country"
    }
  ]
};
