import React, { useCallback, useEffect, useState } from 'react';
import { Grid } from "@mui/material";
import cx from "classnames";
import styles from "./payments.module.scss"
import { useSelector } from 'react-redux';
import { getCurrencyData } from 'store/selectors/currency';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import moment from "moment";
import styled from 'styled-components';
import PendingPaymentReview from './PendingPaymentReview';
import { CustomTooltip, Dialog, Icon } from 'components/common';
import Otp from 'components/common/Otp';
import { useDispatch } from "react-redux";
import { actions } from './slice';
import selectState from "./selectors";
import {getUserTypes} from "store/selectors/auth"
import { UserType, getCurrentUser } from "utils/auth";
import { toCapitalize } from 'utils/helpers';
import NumberFormat from 'react-number-format';

const StyledPaymentRequestModal = styled.div`
  text-align: center;
  h2 {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;
    color: #111326;
    padding: 0 70px;
    
    @media (max-width: 992px) {
      padding: 0 20px;
    }
  }
`;

const TableHeaderRow = styled(TableRow)(() => ({
    backgroundColor: 'rgba(0,160,155,0.19)',
}));


const StyledTableRow = styled(TableRow)(() => ({
    '&:nth-of-type(even)': {
        backgroundColor: 'rgba(0,160,155,0.05)',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const PendingPaymentsList = ({ pendingPayments, refreshHandler }) => {

    const { 
        approvePaymentOtpToken,
        approvePaymentStatus, 
        approvePaymentOtpStatus, 
        approvePaymentOtpCount, 
        approvePaymentOtpError, 
        rejectPendingPaymentStatus,
     } = selectState();

    const dispatch = useDispatch();
    const [currencySymbol, setCurrencySymbol] = useState('£');
    const [paymentActionAllowed, setPaymentActionAllowed] = useState(false);
    const { currency } = useSelector(getCurrencyData);
    const {user_id} = useSelector(getCurrentUser);
    const userTypes = useSelector(getUserTypes);
    const [reviewModal, setReviewModal] = useState(false);
    const [selectedPendingPayment, setSelectedPendingPayment] = useState(null);
    const [approvePaymentModalOpen, setApprovePaymentModalOpen] = useState(false);
    const [rejectPaymentModalOpen, setRejectPaymentModalOpen] = useState(false);
    const [approvePaymentOtp, setApprovePaymentOtp] = React.useState("");

    const clearReviewData = useCallback(() => {
        setSelectedPendingPayment(null);
        setReviewModal(false);
    }, []);

    const clearApprovalData = useCallback(() => {
        dispatch(actions.clearApprovalData());
        setApprovePaymentModalOpen(false);
        setApprovePaymentOtp("");
    }, [dispatch]);

    const clearRejectData = useCallback(() => {
        dispatch(actions.clearRejectData());
        setRejectPaymentModalOpen(false);
    }, [dispatch]);

    useEffect(() => {
        setCurrencySymbol(currency === 'GBP' ? '£' : '€');
    }, [currency]);

    useEffect(() => {
        if (!approvePaymentOtpStatus) {
            return;
        }
        if (approvePaymentOtpStatus === 'success') {
            setApprovePaymentModalOpen(true);
        }
    }, [approvePaymentOtpStatus]);

    useEffect(() => {
        if (!rejectPendingPaymentStatus) {
            return;
        }
        if (rejectPendingPaymentStatus === 'success') {
            clearRejectData();
            refreshHandler();
        }
        if (rejectPendingPaymentStatus === 'failed') {
            alert('Reject Payment failed');
        }
    }, [rejectPendingPaymentStatus, clearRejectData, refreshHandler]);

    useEffect(() => {
        if (!approvePaymentStatus) {
            return;
        }
        if (approvePaymentStatus === 'success') {
            clearApprovalData();
            refreshHandler();
        }
        if (approvePaymentStatus === 'failed') {
            alert('Approve Payment failed');
        }
    }, [approvePaymentStatus, refreshHandler, clearApprovalData]);

    useEffect(() => {
        const allowedRoles = [UserType.Approver, UserType.ExternalUser];
        setPaymentActionAllowed(allowedRoles.some(allowedRole => userTypes.includes(allowedRole)));
    }, [userTypes]);

    const rejectPaymentRequest = useCallback((payment_id) => {
        dispatch(actions.rejectPendingPayments({ payment_id }));
    }, [dispatch]);

    const approvePaymentRequest = useCallback((payment_id) => {
        dispatch(actions.approvePendingPayments({ 
            payment_id,
            approvePaymentOtpToken,
            approvePaymentOtp,
        }));
    }, [dispatch, approvePaymentOtpToken, approvePaymentOtp]);

    const reviewPendingPayment = useCallback((payment) => {
        setReviewModal(true);
        setSelectedPendingPayment(payment);
    }, []);

    const isActionAllowed = useCallback((payment) => {
        return (payment?.status === 'created' || payment?.status === 'retry') && payment?.created_by_user_id !== user_id;
    }, [user_id]);

    const getPaymentStatus = useCallback((status) => {
        switch (status) {
            case 'processing':
                return 'pending-circle';
            case 'approved':
                return 'success-circle';
            case 'rejected':
                return 'failed-circle';
            case 'created':
                return 'created';
            case 'expired':
                return 'warning-status';    
            case 'retry':
                return 'retry';
            default:
                return '';
        }    
    }, []);

    const handleResend = useCallback(() => {
        setApprovePaymentOtp('');
        dispatch(actions.getApprovePaymentOtp({
            amount: selectedPendingPayment?.amount,
            to: selectedPendingPayment?.destination_account_name,
            currency: currency,
            payment_id: selectedPendingPayment?.id,
        }));
    }, [dispatch, currency, selectedPendingPayment]);

    return (
        <Grid item md={12} xs={12} className={cx(styles.list)}>
            <TableContainer className={cx(styles.table)}>
                <Table>
                    <TableHead>
                        <TableHeaderRow>
                            <TableCell></TableCell>
                            <TableCell>Request ID</TableCell>
                            <TableCell>From Account</TableCell>
                            <TableCell>To Account</TableCell>
                            <TableCell>Reference</TableCell>
                            <TableCell>Amount</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Details</TableCell>
                        </TableHeaderRow>
                    </TableHead>
                    <TableBody>
                        {pendingPayments?.length > 0 && pendingPayments.map(payment => (
                            <StyledTableRow key={payment.alias_id}>
                                <TableCell>
                                    {paymentActionAllowed && isActionAllowed(payment)
                                        && <CustomTooltip info={'Click to Approve or Reject'}> 
                                            <Icon name={'approve-payment-action'} disabled={payment.status === 'processing'}
                                            onClick={() => {
                                                reviewPendingPayment(payment);
                                            }} />
                                        </CustomTooltip>}
                                </TableCell>
                                <TableCell>
                                    <span className={cx(styles.view)} onClick={() => reviewPendingPayment(payment)}>{payment.alias_id}</span>
                                </TableCell>
                                <TableCell>
                                    <div>{payment.source_account_name}</div>
                                    {payment.source_account_number && <div>
                                        <b>Acc#: </b> {payment.source_account_number}
                                    </div>}
                                    {payment.source_account_sort_code && <div>
                                        <b>Sort code: </b> {payment.source_account_sort_code}
                                    </div>}
                                    {payment.source_account_iban && <div>
                                        <b>IBAN: </b> {payment.source_account_iban}
                                    </div>}
                                    {payment.source_account_bic && <div>
                                        <b>BIC: </b> {payment.source_account_bic}
                                    </div>}
                                </TableCell>
                                <TableCell>
                                    <div>{payment.destination_account_name}</div>
                                    {payment.destination_account_number && <div>
                                        <b>Acc#: </b> {payment.destination_account_number}
                                    </div>}
                                    {payment.destination_account_sort_code && <div>
                                        <b>Sort code: </b> {payment.destination_account_sort_code}
                                    </div>}
                                    {payment.destination_account_iban && <div>
                                        <b>IBAN: </b> {payment.destination_account_iban}
                                    </div>}
                                    {payment.destination_account_bic && <div>
                                        <b>BIC: </b> {payment.destination_account_bic}
                                    </div>}
                                </TableCell>
                                <TableCell>
                                    {payment.reference}
                                </TableCell>
                                <TableCell>
                                    <NumberFormat value={payment.amount} thousandSeparator={true} displayType={'text'} decimalScale={2} prefix={currencySymbol}/>
                                </TableCell>
                                <TableCell style={{ textTransform: "capitalize" }}>
                                    <CustomTooltip info={'Status: ' + toCapitalize(payment.status)}>
                                        <Icon name={getPaymentStatus(payment.status)} width="32px" />
                                    </CustomTooltip>
                                </TableCell>
                                <TableCell>
                                    {payment.created_at &&
                                        <div> Created by {payment.created_by} on {moment(payment.created_at).format('DD MMM YYYY HH:mm:ss')}
                                        </div>}
                                    {payment.approved_at &&
                                        <div> Approved by {payment.approved_by} on {moment(payment.approved_at).format('DD MMM YYYY HH:mm:ss')}
                                        </div>}
                                    {payment.transaction_id && 
                                        <div> Transaction ID is {payment.transaction_id}</div>}
                                    {payment.rejected_at &&
                                        <div> Rejected by {payment.rejected_by} on {moment(payment.rejected_at).format('DD MMM YYYY HH:mm:ss')}
                                        </div>}
                                    {payment.status === 'expired' &&
                                        <div> Expired on {moment(payment.expires_at).format('DD MMM YYYY HH:mm:ss')}
                                        </div>}
                                </TableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog
                fullWidth
                open={reviewModal}
                setOpen={(val) => {
                    setReviewModal(val);
                    clearReviewData();
                }}
                className={'review-modal'}
                isDisable={!paymentActionAllowed}
                submitButton={isActionAllowed(selectedPendingPayment) ? 'Approve': null}
                cancelButton={isActionAllowed(selectedPendingPayment) ?'Reject': null}
                cancelAction={() => setRejectPaymentModalOpen(true)}
                submitAction={() => {
                    handleResend();
                }}
                isSingleButton={false}
                paperProps={{
                    maxWidth: '100%',
                    width: '80%',
                }}
                backdropProps={{
                    style: {
                        backgroundColor: 'rgba(255,255,255, 0.8)'
                    }
                }}>
                <PendingPaymentReview payment={selectedPendingPayment} />
            </Dialog>

            <Dialog
                fullWidth
                open={approvePaymentModalOpen}
                isSingleButton={false}
                className={'approve-payment-modal'}
                setOpen={(val) => {
                    clearApprovalData();
                }}
                submitButton={null}
                isActions={false}
                backdropProps={{
                    style: {
                        backgroundColor: 'rgba(255,255,255, 0.8)'
                    }
                }}
            >
                <div className={cx(styles.dialog)}>
                    <Otp
                        heading={'Enter one-time password sent to the registered mobile number.'}
                        otp={approvePaymentOtp}
                        onChange={setApprovePaymentOtp}
                        disabled={approvePaymentOtp.length !== 6}
                        length={6}
                        onSubmit={() => approvePaymentRequest(selectedPendingPayment.id)}
                        onResend={handleResend}
                        error={approvePaymentOtpError}
                        buttonText={"Submit"}
                        otpCount={approvePaymentOtpCount}
                        maxTime={45}
                        isSecured={true}
                    />
                </div>
            </Dialog>

            <Dialog
                fullWidth
                open={rejectPaymentModalOpen}
                isSingleButton={false}
                className={'reject-payment-modal'}
                setOpen={(val) => setRejectPaymentModalOpen(val)}
                submitButton={'Confirm'}
                cancelButton={'Cancel'}
                submitAction={() => rejectPaymentRequest(selectedPendingPayment.id)}
                cancelAction={() => setRejectPaymentModalOpen(false)} // On cancel close the modal
                isActions={true}
                backdropProps={{
                    style: {
                        backgroundColor: 'rgba(255,255,255, 0.8)'
                    }
                }}
            >
                <StyledPaymentRequestModal>
                    <>
                        <h2> Are you sure you want to reject the payment request?</h2>
                    </>
                </StyledPaymentRequestModal>
            </Dialog>

        </Grid>
    );
};
export default PendingPaymentsList;