import moment from "moment";

export const getInitials = (name) => {
    const array = name?.match(/\b(\w)/g);
    return ((array?.shift() || '') + '' + (array?.pop() || '')).toUpperCase();
};

export const validateEmail = (email) => {
  const re = /^[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{1,4}$/;
  return re.test(email);
}

export const validateReference = (val) => {
    const re = /^[A-Za-z\d\-.&/\s]*$/;
    return re.test(val);
}

export const sortCodeCleaner = (code) => {
  // return code.replace(/^(?!(?:0{6}|00-00-00))(?:\d{6}|\d\d-\d\d-\d\d)$/, '').toUpperCase();
}

export const toCapitalize = (string = '') => {
  return string?.toLowerCase().replace(/(^\w{1})|(\s{1}\w{1})/g, match => match?.toUpperCase())
}

export const getHighlightedText = (text, highlight) => {
  let groups = highlight.split(" ").map(v => `(${v})`).join("|");
  return "<span>" + text.replace(new RegExp(groups, "gi"), g => `<span style="color:#93C74B">${g}</span>`) + "<span>";
}

export const groupBy = (items, key) => items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [
        ...(result[item[key]] || []),
        item,
      ],
    }),
    {},
);

export const isProd = process.env.REACT_APP_ENVIRONMENT === 'production';

export const clean = obj => {
    for (let propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
            delete obj[propName];
        }
    }
    return obj
}

export const getQueryParams = values => {
    const searchParams = new URLSearchParams()
    for (let key in values) {
        if (values[key] !== null && values[key] !== '') {
            if(values[key] instanceof Date) {
                values[key] = moment(values[key]).format('YYYY-MM-DD');
            }
            if(values[key] === 'all') {
                searchParams.delete(key);
            } else {
                searchParams.set(key, values[key]);
            }
        }
    }
    return searchParams.toString().toLocaleLowerCase();
}


export const kFormatter = (num) => Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num);