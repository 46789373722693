import React, { useEffect, useState } from "react";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button  from 'components/common/Button';
import cx from "classnames";
import styles from "./send-funds.module.scss";
import SelectRecipient from "./SelectRecipient";
import EnterAmount from "./EnterAmount";
import Confirm from "./Confirm";
import Review from "./Review";
import {Grid} from "@mui/material";
import selectState from './selectors';
import { actions } from "./slice";
import { useDispatch, useSelector } from "react-redux";
import CustomStepperIcon from "./StepperContent";
import styled from "styled-components/macro";
import Icon from "../../common/Icon";
import {useSearchParams} from "react-router-dom";
import selectAccountsState from '../account/selectors';
import { getCurrencyData } from "store/selectors/currency";
import {getCompanyData} from "store/selectors";

const steps = [
    'Select recipient',
    'Enter amount',
    'Review details',
    'Confirm transfer',
];

const CustomStepLabel = styled(StepLabel)(({ ownerState }) => ({
    ...(ownerState.completed && {
        color: '#00A09B !important',
    }),
    ...(ownerState.active && {
        color: '#00A09B',
    }),
    ...(ownerState.error && {
        color: '#FF8888 !important',
    }),

}));

const StyledLabel = styled('span')(({ ownerState }) => ({
    ...(ownerState.completed && {
        color: '#00A09B !important',
    }),
    ...(ownerState.active && {
        color: '#00A09B !important',
    }),
}));

const StyledOffsetGrid = styled(Grid)`
  @media (max-width: 768px) {
    display: none;
  }
`

const renderStepContent = (step, fromAccount) => {
    switch (step) {
        case 0:
            return <SelectRecipient />;
        case 1:
            return<EnterAmount />;
        case 2:
            return <Review />;
        case 3:
            return <Confirm />;
        default:
            return 'Unknown step';
    }
}


const SendFunds = () => {
    const dispatch = useDispatch();
    const {accounts} = selectAccountsState();
    const {currency} = useSelector(getCurrencyData);
    const companyData = useSelector(getCompanyData);

    const [searchParams] = useSearchParams();
    const [fromAccountId, setFromAccountId] = useState(null);

    const {
        currentFundStep,
        disableStep,
        transactionStatus,
        referenceInfo,
        fromAccount,
    } = selectState();

    const isLastStep = currentFundStep === steps.length - 1;

    useEffect(() => {
        dispatch(actions.getAccount({type:'main_account'}));
        return () => {
            dispatch(actions.resetFunds());
        }
    }, [dispatch]);
    
    useEffect(() => {
        if (! searchParams.get('from')) {
            // If we don't have from param, use main account
            setFromAccountId(accounts?.items?.filter(account => account.currency === currency)?.[0]?.id);
        } else {
            setFromAccountId(searchParams.get('from'));
        }
    }, [searchParams, accounts, currency]);

    useEffect(() => {
        if (!fromAccountId) {
            return;
        }
        dispatch(actions.fetchFromAccount(fromAccountId));
    }, [dispatch, fromAccountId]);

    useEffect(() => {
    }, [fromAccount]);

    const handleNext = () => {
       if(isLastStep) {
           dispatch(actions.setCurrentFundStep(0));
       } else {
           dispatch(actions.setCurrentFundStep(currentFundStep + 1));
       }
       if(currentFundStep === 1) dispatch(actions.setIsEditTransaction(false));
    };

   const isStepError = (step) => {
       return step === 3 && transactionStatus === 'error';
   }

   const StepIconError = () => {
       return <CustomStepperIcon error={true} />;
   }

    const StepIcon = (props) => {
       const { active, completed, className, error } = props;
        return <CustomStepperIcon
                    active={active}
                    completed={completed}
                    error={error}
                    className={className}
                    finalStepCompleted={isLastStep && transactionStatus === 'success'}
                />;
    }

    const StepIconSuccess = () => {
        return <Icon name="passed" className="completedIcon" />;
    }


    return (
    <Grid className={cx(styles['send-funds'])} container={isLastStep}>
        {/*{!isLastStep &&*/}
            <Grid md={12} item style={{width: '100%'}}>
                <h3 className={cx(styles.legalName)}>{`${companyData?.data?.legal_name || ''}`}</h3>
            </Grid>
            <Grid item xs={8}>
                <h1 className={cx(styles['page-title'])}>Send funds</h1>
            </Grid>
        {/*}*/}
        {isLastStep &&
            <StyledOffsetGrid item md={2}  />
        }
      <Grid item md={12} xs={12} sm={12} lg={12} xl={6}>
          <Stepper
              activeStep={currentFundStep}
              className={cx(styles['stepper-wrapper'])}
              alternativeLabel
              connector={<div className={cx(styles['stepper-connector'])}>
              <span />
              </div>}
          >
              {steps.map((label, index) => {

                  if (isStepError(index)) {
                      return (
                          <Step key={label}>
                              <CustomStepLabel
                                  StepIconComponent={StepIconError}
                                  ownerState={{
                                      error: isStepError(index),
                                  }}
                              >
                                  <StyledLabel
                                      ownerState={{
                                          error: isStepError(index),
                                      }}
                                      style={{
                                          color: '#FF8888',
                                      }}
                                  >
                                      {label}
                                  </StyledLabel>
                              </CustomStepLabel>
                          </Step>
                      );
                  }
                  if(isLastStep && transactionStatus === 'success') {
                     return (
                         <Step key={label}>
                             <CustomStepLabel
                                 StepIconComponent={StepIconSuccess}
                                 ownerState={{
                                     active: currentFundStep === index,
                                     error: isStepError(index),
                                     completed: currentFundStep > index,
                                 }}
                             >
                                 <StyledLabel
                                     ownerState={{
                                         active: currentFundStep === index,
                                         completed: currentFundStep > index,
                                     }}
                                 > {label}</StyledLabel>
                             </CustomStepLabel>
                         </Step>
                     )
                  }
                  return (
                      <Step key={label}>
                          <CustomStepLabel
                              StepIconComponent={StepIcon}
                              ownerState={{
                                  active: currentFundStep === index,
                                  error: isStepError(index),
                                  completed: currentFundStep > index,
                              }}
                          >
                              <StyledLabel
                                    ownerState={{
                                      active: currentFundStep === index,
                                      completed: currentFundStep > index,
                                  }}
                              > {label}</StyledLabel>
                          </CustomStepLabel>
                      </Step>
                  );
              })}
          </Stepper>
      </Grid>
        {renderStepContent(currentFundStep, fromAccount)}
        {(currentFundStep !== 2) && (
        <Grid container>
            <Grid item md={12} xs={12} sm={12} lg={12} xl={6}>
                <Grid container spacing={2} justify="flex-start">
                    {currentFundStep !== 0 && !isLastStep && (
                        <Grid item md={6} xs={12} mb={2} order={{ xs: 2, sm: 2, md: 1 }}>
                            <Button
                                variant="outlined"
                                text="Back"
                                onClick={() => dispatch(actions.setCurrentFundStep(currentFundStep - 1))}
                            />
                        </Grid>
                    )}
                    {currentFundStep !== 0 &&
                        <Grid item md={currentFundStep !== 0 && !isLastStep ? 6 : 12} xs={12} order={{ xs: 1, sm: 1, md: 1 }}>
                            <Button
                                text={
                                    ((isLastStep && transactionStatus === "success") && 'Send to another recipient')
                                    || ((isLastStep && transactionStatus === "error") && 'Try again')
                                    || 'Continue'
                                }
                                className={cx(styles['another'])}
                                size={((isLastStep && transactionStatus === "success") && 'lg') || ((isLastStep && transactionStatus === "error") && 'lg') || null}
                                variant="gradient"
                                onClick={handleNext}
                                disabled={disableStep || !!referenceInfo}
                            />
                        </Grid>
                    }
                </Grid>
            </Grid>
        </Grid>
            )}
    </Grid>
  );
};

export default SendFunds;