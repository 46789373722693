import {combineReducers} from 'redux';
import auth from "./auth";
import companyType from "./companyType";
import companyMembers from "./companyMembers";
import industryType from "./industryType";
import fundSource from "./fundSource";
import registerOwner from "./registerOwner";
import user from "./user";
import companies from "./companies";
import currency from './currency';
import forgotPassword from "./forgotPassword";
import authorize from "./authorize";
import kyc from "./kyc";
import password from "./password";
import layout from "./layout";
import companyData from "./companyData";
import checkEmail from "./checkEmail";
import members from "./members";
import { reducer as recipientsReducer } from "components/dashboard/recipients/slice";
import otp from "./otp";
import { reducer as fundsReducer } from "components/dashboard/funds/slice";
import { reducer as appReducer } from "AppSlice";
import { reducer as transactionReducer } from "components/dashboard/transactions/slice";
import { reducer as accountsReducer } from "components/dashboard/account/slice";
import { reducer as paymentsReducer } from "components/dashboard/pendingpayments/slice";
import { reducer as profileReducer } from "components/dashboard/profile/slice";
import { reducer as developerReducer } from "components/dashboard/developer/slice";
import { reducer as contentReducer } from "components/conditions/slice";
import documents from "./documents";

const RESET_STORE = '@AUTH/RESET_STORE';

const RESTART_APP = '@APP/RESTART_APP';

export const clearState = () => ({
    type: RESET_STORE
});

export const restartApp = () => ({
    type: RESTART_APP
});

const storeReducer = combineReducers({
    app: appReducer,
    auth: auth,
    companyType: companyType,
    companyMembers: companyMembers,
    currency: currency,
    industryType: industryType,
    fundSource: fundSource,
    registerOwner: registerOwner,
    user: user,
    companies: companies,
    forgotPassword: forgotPassword,
    authorize: authorize,
    kyc: kyc,
    password: password,
    layout: layout,
    companyData: companyData,
    checkEmail: checkEmail,
    members: members,
    recipients: recipientsReducer,
    otp: otp,
    funds: fundsReducer,
    transactions: transactionReducer,
    accounts: accountsReducer,
    payments: paymentsReducer,
    profile: profileReducer,
    developer: developerReducer,
    documents: documents,
    content: contentReducer
});

const rootReducer = (state, action) => {
    if(action.type === RESET_STORE) {
        localStorage.clear();
        return storeReducer(undefined, action)
    }
    if(action.type === RESTART_APP) {
        const updateState = {
            ...state.auth,
        } 
        return storeReducer(updateState, action);
    }
    return storeReducer(state, action)
}

export default rootReducer;