import React from 'react';
import {Grid} from "@mui/material";
import cx from 'classnames';
import styles from '../send-funds.module.scss'
import selectState from '../selectors';
import {Icon} from "../../../common";


const PaymentRequestConfirm = () => {
    const {
        paymentRequestStatus,
        paymentRequestError,
    } = selectState();

  return (
      <Grid item xs={12} md={12} lg={12} sm={12} className={cx(styles['confirm-step'])}>
          <Grid container mb={4}>
              <Grid item md={12} xs={12} sm={12} lg={12} xl={6}>

                 {paymentRequestStatus && paymentRequestStatus === 'success' && (
                    <>
                        <Grid item md={12} xs={12} sm={12}>
                            <h1 className={cx(styles['confirm-title'])}>
                                Payment Request created successfully!
                            </h1>
                        </Grid>
                        <Grid item md={12} xs={12} sm={12}>
                            <Grid item md={12} xs={12} sm={12}>
                                <Icon name={'funds-success'} size={'226px'}/>
                            </Grid>
                        </Grid>
                    </>
                 )}
                 {paymentRequestStatus && paymentRequestStatus === 'error' && (
                     <>
                         <Grid item md={12} xs={12} sm={12}>
                             <h1 className={cx(styles['confirm-title-error'])}>
                                 {paymentRequestError ?? 'Something went wrong!'}
                             </h1>
                         </Grid>
                         <Grid item md={12} xs={12} sm={12}>
                             <Icon name={'funds-error'} size={'175px'}/>
                         </Grid>
                     </>
                 )}
             </Grid>
          </Grid>
      </Grid>
  );
};

export default PaymentRequestConfirm;