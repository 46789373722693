import {
    POST_LOGIN_PENDING, POST_LOGIN_SUCCESS, POST_LOGIN_FAILURE,
    POST_LOGIN_OTP_PENDING, POST_LOGIN_OTP_SUCCESS, POST_LOGIN_OTP_FAILURE,
    POST_SIGNUP_PENDING, POST_SIGNUP_SUCCESS, POST_SIGNUP_FAILURE,
    CHANGE_PASSWORD_PENDING, CHANGE_PASSWORD_SUCCESS, CHANGE_PASSWORD_FAILURE,
    POST_AUTHORIZE_PENDING, POST_AUTHORIZE_SUCCESS, POST_AUTHORIZE_FAILURE,
    POST_FORGOT_PASSWORD_PENDING, POST_FORGOT_PASSWORD_SUCCESS, POST_FORGOT_PASSWORD_FAILURE,
    POST_CHECK_EMAIL_PENDING, POST_CHECK_EMAIL_SUCCESS, POST_CHECK_EMAIL_FAILURE,
    LOGOUT, CLEAR_STATE, CLEAR_ERRORS, CLEAR_PASSWORD_MESSAGE, CLEAR_SET_PASSWORD,
    SET_RE_CAPTCHA_TOKEN
} from '../actionTypes';

//login
export const setReCaptchaToken = (data) => ({
    type: SET_RE_CAPTCHA_TOKEN,
    data
})


export const postLoginPending = (option) => ({
    type: POST_LOGIN_PENDING,
    option
})

export const postLoginSuccess = (data) => ({
    type: POST_LOGIN_SUCCESS,
    data
})

export const postLoginFailure = (message, status) => ({
    type: POST_LOGIN_FAILURE,
    message,
    status
})

//login-otp
export const postLoginOtp = (option) => ({
    type: POST_LOGIN_OTP_PENDING,
    option
})

export const postLoginOtpSuccess = (data) => ({
    type: POST_LOGIN_OTP_SUCCESS,
    data
})

export const postLoginOtpFailure = (message, status) => ({
    type: POST_LOGIN_OTP_FAILURE,
    message,
    status
})

//signup
export const postSignupPending = (option) => ({
    type: POST_SIGNUP_PENDING,
    option
})

export const postSignupSuccess = (data) => ({
    type: POST_SIGNUP_SUCCESS,
    data
})

export const postSignupFailure = (message, status) => ({
    type: POST_SIGNUP_FAILURE,
    message,
    status
})

//logout
export const logout = (data) => ({
    type: LOGOUT,
    data
})

export const clearState = () => ({
    type: CLEAR_STATE
})

export const clearErrors = (option) => ({
    type: CLEAR_ERRORS,
    option
})

//reset password
export const changePasswordPending = (option) => ({
    type: CHANGE_PASSWORD_PENDING,
    option
})

export const changePasswordSuccess = (data) => ({
    type: CHANGE_PASSWORD_SUCCESS,
    data
})

export const changePasswordFailure = (error) => ({
    type: CHANGE_PASSWORD_FAILURE,
    error
})

///check authorization
export const postAuthorizePending = (option) => ({
    type: POST_AUTHORIZE_PENDING,
    option
})

export const postAuthorizeSuccess = (data) => ({
    type: POST_AUTHORIZE_SUCCESS,
    data
})

export const postAuthorizeFailure = (error) => ({
    type: POST_AUTHORIZE_FAILURE,
    error
})

//FORGOT PASSWORD
export const postForgotPasswordPending = (option) => ({
    type: POST_FORGOT_PASSWORD_PENDING,
    option
})

export const postForgotPasswordSuccess = (data) => ({
    type: POST_FORGOT_PASSWORD_SUCCESS,
    data
})

export const postForgotPasswordFailure = (message, status) => ({
    type: POST_FORGOT_PASSWORD_FAILURE,
    message,
    status
})

//CHECK EMAIL
export const postCheckEmailPending = (option) => ({
    type: POST_CHECK_EMAIL_PENDING,
    option
})

export const postCheckEmailSuccess = (data) => ({
    type: POST_CHECK_EMAIL_SUCCESS,
    data
})

export const postCheckEmailFailure = (message, status) => ({
    type: POST_CHECK_EMAIL_FAILURE,
    message,
    status
})

export const clearPasswordMessage = () => ({
    type: CLEAR_PASSWORD_MESSAGE
})

export const clearSetPassword = () => ({
    type: CLEAR_SET_PASSWORD
})
