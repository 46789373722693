import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Icon from "./Icon";
import Button from './Button'

import styles from "../../assets/styles/common.module.scss";
import cx from 'classnames';

const CustomDialog = (props) =>  {
    const {
        open, setOpen, children, submitAction,
        cancelAction, isDisable, className,
        cancelButton= 'Cancel', submitButton='Delete',
        isSingleButton = true, fullWidth, //TODO: investigate single button case
        backdropProps,
        paperProps = {},
        isActions = true,
    } = props

    const handleClose = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    return (
        <Dialog
            className={cx(styles[className])}
            open={open}
            onClose={handleClose}
            fullWidth={fullWidth}
            PaperProps={{
                style: {
                    borderRadius: '30px',
                    border: '1px solid #c4c4c4',
                    ...paperProps
                },
            }}
            BackdropProps={{
                ...backdropProps
            }}
        >
            <Icon name={'close'} className={cx(styles.closeIcon)} onClick={handleClose}/>
            <DialogContent className={cx(styles.dialogContent)}>
                {children}
            </DialogContent>
            {(isActions) &&
                <DialogActions className={cx(styles.actionGroup)}>
                    {cancelButton && !isSingleButton &&
                        <Button
                            className={cx(styles.cancelBtn)}
                            text={cancelButton}
                            variant="outlined"
                            // margin={'0 10px'}
                            onClick={cancelAction}
                            disabled={isDisable}
                        />
                    }
                    {submitButton &&
                        <Button
                            className={cx(styles.submitBtn)}
                            text={submitButton}
                            onClick={submitAction}
                            disabled={isDisable}
                        />
                    }
                </DialogActions>
            }
        </Dialog>
    );
}

export default CustomDialog;

CustomDialog.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    children: PropTypes.node,
    submitAction: PropTypes.func,
    cancelAction: PropTypes.func,
    isDisable: PropTypes.bool,
    className: PropTypes.string,
    cancelButton: PropTypes.string,
    submitButton: PropTypes.string,
    isSingleButton: PropTypes.bool,
    fullWidth: PropTypes.bool,
    backdropProps: PropTypes.object,
}