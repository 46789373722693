import {put, select, call, takeLatest} from 'redux-saga/effects';
import {actions} from "./slice";
import { request } from 'utils/api';
import Endpoints from 'utils/endpoints';
import moment from "moment";
import { getCurrencyData } from 'store/selectors/currency';
import {getAccessToken} from "utils/auth";

const { payment_request } = Endpoints;


export function* getPendingPayments(action) {
    const {page, size, queryParams} = action.payload;
    const searchParameters = new URLSearchParams(queryParams);
    const from_date = searchParameters.get('from_date');
    const to_date = searchParameters.get('to_date');
    if(from_date){
        searchParameters.set('from_date', moment(from_date+'T00:00:00').format('X'))
    }
    if(to_date){
        searchParameters.set('to_date', moment(to_date + 'T23:59:59').format('X'))
    }

    try {
        const {currency} = yield select(getCurrencyData);
        const {data} = yield call(request().get, payment_request.get.url(page, size, searchParameters.toString(), currency));
       if(data) {
        yield put(actions.getPendingPaymentsSuccess(data));
       }
    } catch (err) {
        yield put(actions.getPendingPaymentsError(err.message));
    }
}

export function* getApprovePaymentOtp(action) {
    const {amount, currency, to, payment_id} = action.payload;
    try {
        const response = yield call(
            request().post,
            'auth/generate-request-otp',
            {
                message: `{otp} is your Merge OTP for the amount ${amount} ${currency} for ${to}. Do not share.`,
                subject: 'Approval of pending payment is initiated',
                request: {
                    path: `//v1/payment-request/${payment_id}/approve`,
                    method: 'POST',
                    payload: {},
                },
                token: getAccessToken(),
                delivery_channel: 'sms',
            },
        );
        if(response) {
            yield put(actions.getApprovePaymentOtpSuccess(response.data));
        }
    } catch (err) {
        yield put(
            actions.getApprovePaymentOtpError({
                message: err.message,
                status: 403,
            }));
    }
}


export function* approvePendingPayment(action) {
    try {
        const {payment_id} = action.payload;
        const {data} = yield call(request().post, 
            `${payment_request.post.url}/${payment_id}/approve`, {}, {
                headers: {
                    "X_OTP_CODE": action.payload.approvePaymentOtp,
                    "X_OTP_TOKEN": action.payload.approvePaymentOtpToken
                }
            });
        if(data) {
            yield put(actions.approvePendingPaymentsSuccess(data));
        }
    } catch (err) {
        yield put(actions.approvePendingPaymentsError({
            message: 'Invalid OTP',
            status: 403,
        }));
    }    
}


export function* rejectPendingPayment(action) {
    try {
        const {payment_id} = action.payload;
        const {data} = yield call(request().post, `${payment_request.post.url}/${payment_id}/reject`, {});
       if(data) {
        yield put(actions.rejectPendingPaymentsSuccess(data));
       }
    } catch (err) {
        yield put(actions.rejectPendingPaymentsError(err.message));
    }    
}

export function* paymentsSaga() {
    yield takeLatest(actions.getPendingPayments.type, getPendingPayments);
    yield takeLatest(actions.rejectPendingPayments.type, rejectPendingPayment);
    yield takeLatest(actions.getApprovePaymentOtp.type, getApprovePaymentOtp);
    yield takeLatest(actions.approvePendingPayments.type, approvePendingPayment);
}
