import React, { useCallback, useEffect, useState } from 'react';
import {Grid, List} from "@mui/material";
import cx from "classnames";
import styles from "../../../transactions/transaction.module.scss"
import {CustomList, Icon} from "../../../../common";
import moment from "moment";
import CustomTooltip from "../../../../common/CustomTooltip";
import {toCapitalize} from "../../../../../utils/helpers";
import { useSelector } from 'react-redux';
import { getCurrencyData } from 'store/selectors/currency';
import TransactionDetails from 'components/dashboard/transactions/components/TransactionDetails';
import NumberFormat from 'react-number-format';

const TransactionList = ({transactions: {items}}) => {
    const {currency} = useSelector(getCurrencyData);
    const [currencySymbol, setCurrencySymbol] = useState('£');
    const [selectedId, setSelectedId] = useState(null);
    const handleTransactionClick = useCallback(id => setSelectedId(selectedId === id ? null: id ), [selectedId]);

    useEffect(() => {
        setCurrencySymbol(currency === 'GBP'? '£' : '€');
    }, [currency]);

    const getTransactionStatusToolTip = (transaction) => {
        return toCapitalize(transaction.status) + (transaction.status_note ? `: ${transaction.status_note}`: '');        
    }
    
    const getSubtitle = (transaction) => {
        var subtitle = "";
        if (transaction.type === 'funds_in') {
            if (transaction.source_account_number) {
                subtitle += `Acc number: ${transaction.source_account_number} ,`;
            }
            if (transaction.source_sort_code) {
                subtitle += `Sort code: ${transaction.source_sort_code}`;
            }
            if (transaction.source_iban) {
                subtitle += `IBAN: ${transaction.source_iban}, `;
            }
            if (transaction.source_bic) {
                subtitle += `BIC: ${transaction.source_bic} `;
            }
        } else {
            if (transaction.destination_account_number) {
                subtitle += `Acc number: ${transaction.destination_account_number}, `;
            }
            if (transaction.destination_sort_code) {
                subtitle += `Sort code: ${transaction.destination_sort_code}`;
            }
            if (transaction.destination_iban) {
                subtitle += `IBAN: ${transaction.destination_iban}, `;
            }
            if (transaction.destination_bic) {
                subtitle += `BIC: ${transaction.destination_bic} `;
            }
        }
        return subtitle;
    };

    const getTransactionSign = (transaction, currencySymbol) => {
        if (transaction.type === 'funds_in') {
            return `+ ${currencySymbol}`
        } else {
            return `- ${currencySymbol}`
        }
    }

    const getTransactionStatusIcon = (transaction) => {
        switch (transaction.status) {
            case 'processing':
                return 'pending-circle';
            case 'successful':
                return 'success-circle';
            case 'failed':
                return 'failed-circle';
            default:
                break;
        }
    }

    return (
        <Grid item md={12} xs={12} className={cx(styles.list)}>
            {items?.length > 0 && items.map((item) => (
                <List key={item.day}>
                    <h1 className={cx(styles.createdDate)}>{moment(item.date).format('DD MMM YYYY')}</h1>
                    {item.transactions?.length > 0 && item.transactions.map((transaction) => (
                        <React.Fragment key={transaction.transaction_id}>
                            <CustomList
                                id={transaction.transaction_id}
                                title={transaction.type === 'funds_in' ?  transaction.source_account_name : transaction.destination_name}
                                avatar={"true"}
                                subtitle={getSubtitle(transaction)}
                                customClass={cx(styles.item)}
                                avatarClass={cx(styles.avatar)}
                                actions={[
                                    <span key={"reference"} className={cx(styles.subStatusRef, ((transaction.type === 'funds_in' && transaction.source_account_name) || ( transaction.type === 'funds_out' && transaction.destination_name)) && styles.subStatusRefNoData)}>
                                            Ref: {transaction.reference?.substring(0, 15)}{transaction.reference?.length >= 18 ? '...': transaction.reference?.substring(15, 18)}
                                    </span>,
                                    <span key={"currency_symbol"} className={styles.subType} style={{color: transaction.type === 'funds_out' ? '#111326' : '#57AD9E'}}>
                                        <NumberFormat prefix={getTransactionSign(transaction, currencySymbol)} value={transaction.amount} thousandSeparator={true} displayType={'text'} decimalScale={2}/>
                                    </span>,
                                    <span key={"txn_status"} className={cx(styles.subStatusIcon)}>
                                        <CustomTooltip info={getTransactionStatusToolTip(transaction)}>
                                            <Icon  name={getTransactionStatusIcon(transaction)}/>
                                        </CustomTooltip>
                                    </span>,
                                    <span className={cx(styles.action)}>
                                        <Icon name={transaction.transaction_id === selectedId ? 'caret-up' : 'caret-down'} color="#C4C4C4" onClick={() => handleTransactionClick(transaction.transaction_id)}/>
                                    </span>
                            ]}
                                collapsibleContent={
                                    <TransactionDetails
                                        transaction={transaction}
                                        isOpen={transaction.transaction_id === selectedId}
                                        index={transaction.transaction_id}
                                    />
                                }
                        />
                        </React.Fragment>
                    ))}
                </List>
            ))}
        </Grid>
    );
};
export default TransactionList;