import React from 'react';
import {Box, Collapse, Divider, Grid} from "@mui/material";
import cx from "classnames";
import styles from "../transaction.module.scss";
import moment from "moment";
import { useSelector } from 'react-redux';
import { getCurrencyData } from 'store/selectors/currency';
import NumberFormat from 'react-number-format';

const TransactionDetails = ({transaction, index, isOpen}) => {
    const {currency} = useSelector(getCurrencyData);

    const transactionFee = transaction.fee_amount ?? 0; // Handling missing txn fee
    const recipientReceivedAmt = Number.parseFloat(transaction.amount - transactionFee).toFixed(2);
    return (
        <Collapse in={isOpen} timeout="auto" unmountOnExit key={index} className={cx(styles.collapsible)}>
            {transaction.status_note && 
                <span><span style={{color: "#777"}}>Failure reason: </span> {transaction.status_note} </span>}
            <Grid container >
                <Grid item md={5} className={styles.details}>
                    <h3>Transaction details</h3>
                    <Grid container sx={{m:2, marginLeft: 0, marginTop: '32px'}}>
                        <Grid item xs={6}><Box color={'#777777'} fontWeight={700}><p>{transaction.type === 'funds_in' ? 'You received' : 'You sent:' }</p></Box></Grid>
                        <Grid item xs={6}>
                            <Box display="flex" justifyContent="flex-end" fontWeight={700} color={'#111326'}>
                                <p>
                                    <NumberFormat value={Number.parseFloat(transaction.amount).toFixed(2)} thousandSeparator={true} displayType={'text'} decimalScale={2} suffix={` ${currency}`}/>                                    
                                    {/* {Number.parseFloat(transaction.amount).toFixed(2)} {currency} */}
                                </p>
                            </Box>
                        </Grid>
                    </Grid>
                    <Divider sx={{borderStyle:'dashed'}}/>
                    {transaction.type === 'funds_out' && <>
                        <Grid container sx={{m:2, marginLeft: 0}}>
                            <Grid item xs={6}><Box color={'#777777'}><p>Recipient received: </p></Box></Grid>
                            <Grid item xs={6}><Box display="flex" justifyContent="flex-end"><p>
                                <NumberFormat value={recipientReceivedAmt} thousandSeparator={true} displayType={'text'} decimalScale={2} suffix={` ${currency}`}/>
                            </p></Box></Grid>
                        </Grid>
                        <Divider sx={{borderStyle:'dashed'}}/>
                    </>}
                    <Grid container sx={{m:2, marginLeft: 0}}>
                        <Grid item xs={4}><Box color={'#777777'}><p>Reference:</p></Box></Grid>
                        <Grid item xs={8}><Box display="flex" justifyContent="flex-end"><p style={{textAlign: 'right'}}>{transaction.reference}</p></Box></Grid>
                    </Grid>
                    <Grid container sx={{m:2, marginLeft: 0}}>
                        <Grid item xs={5}><Box color={'#777777'}><p>Transaction ID:</p></Box></Grid>
                        <Grid item xs={7}><Box display="flex" justifyContent="flex-end" textAlign={'right'}><p>{transaction.transaction_id}</p></Box></Grid>
                    </Grid>
                    {transaction.created_at && <Grid container sx={{m:2, marginLeft: 0}}>
                        <Grid item xs={5}><Box color={'#777777'}><p>Creation time:</p></Box></Grid>
                        <Grid item xs={7}><Box display="flex" justifyContent="flex-end" textAlign={'right'}><p>{moment(transaction.created_at).format('DD MMM YYYY HH:mm:ss')}</p></Box></Grid>
                    </Grid>}
                    {transaction.payment_date && <Grid container sx={{m:2, marginLeft: 0}}>
                        <Grid item xs={5}><Box color={'#777777'}><p>Payment time:</p></Box></Grid>
                        <Grid item xs={7}><Box display="flex" justifyContent="flex-end" textAlign={'right'}><p>{moment(transaction.payment_date).format('DD MMM YYYY HH:mm:ss')}</p></Box></Grid>
                    </Grid>}
                </Grid>
                <Grid item md={5} className={styles.info}>
                    <h3>{transaction.type === 'funds_in' ?  transaction.source_name : transaction.destination_name}</h3>
                    <Grid container sx={{
                        m:2,
                        marginLeft: 0,
                        marginTop: ((transaction.type === 'funds_in' &&  transaction.source_account_name) || (transaction.type === 'funds_out' &&  transaction.destination_name))
                            ? "32px"
                            : '57px'
                    }}>
                        {(transaction.source_account_number || transaction.destination_account_number) && <>
                        <Grid item xs={6}><Box color={'#777777'}><p>Account number:</p></Box></Grid>
                        <Grid item xs={6}>
                            <Box display="flex" justifyContent="flex-end">
                                <p>{transaction.type === 'funds_in' ?  transaction.source_account_number : transaction.destination_account_number}</p>
                            </Box>
                        </Grid></>}
                        {(transaction.source_iban || transaction.destination_iban) && <>
                        <Grid item xs={6}><Box color={'#777777'}><p>IBAN:</p></Box></Grid>
                        <Grid item xs={6}>
                            <Box display="flex" justifyContent="flex-end">
                                <p>{transaction.type === 'funds_in' ?  transaction.source_iban : transaction.destination_iban}</p>
                            </Box>
                        </Grid></>}
                    </Grid>
                    <Grid container sx={{m:2, marginLeft: 0, marginTop: '32px'}}>
                        {(transaction.source_sort_code || transaction.destination_sort_code) && <>
                        <Grid item xs={6}><Box color={'#777777'}><p>Sort code: </p></Box></Grid>
                        <Grid item xs={6}>
                            <Box display="flex" justifyContent="flex-end">
                                <p>
                                    {transaction.type === 'funds_in' ? transaction.source_sort_code : transaction.destination_sort_code}
                                </p>
                            </Box>
                        </Grid></>}
                        {(transaction.source_bic || transaction.destination_bic) && <>
                        <Grid item xs={6}><Box color={'#777777'}><p>BIC: </p></Box></Grid>
                        <Grid item xs={6}>
                            <Box display="flex" justifyContent="flex-end">
                                <p>
                                    {transaction.type === 'funds_in'
                                        ? transaction.source_bic
                                        : transaction.destination_bic}
                                </p>
                            </Box>
                        </Grid></>}
                    </Grid>
                    <Grid container sx={{m:2, marginLeft: 0}}>
                        <Grid item xs={5}>
                            <Box color={'#777777'}>
                                <p>{transaction.type === 'funds_in' ?  'Sender email:' : 'Recipient email:'}</p>
                            </Box>
                        </Grid>
                        <Grid item xs={7}>
                            <Box display="flex" justifyContent="flex-end">
                                <p style={{wordBreak: 'break-all', textAlign: 'right'}}>{transaction.type === 'funds_in' ?  transaction.source_email || 'N/A' : transaction.destination_email || 'N/A'}</p>
                            </Box>
                        </Grid>
                    </Grid>
                    {transaction.type === 'funds_in' && <Grid container sx={{m:2, marginLeft: 0}}>
                        <Grid item xs={6}><Box color={'#777777'}><p>Sender mobile: </p></Box></Grid>
                        <Grid item xs={6}>
                            <Box display="flex" justifyContent="flex-end">
                                <p>{transaction.source_phone || 'N/A'}</p>
                            </Box>
                        </Grid>
                    </Grid>}
                </Grid>
            </Grid>
        </Collapse>
    )
};

export default TransactionDetails;

