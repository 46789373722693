import React, {useCallback, useEffect} from 'react';
import { Grid } from "@mui/material";
import { Button, Dialog } from "components/common";
import cx from "classnames";
import { useDispatch } from "react-redux";
import {actions} from "../slice";
import selectState from '../selectors';
import styles from "../send-funds.module.scss";
import styled from "styled-components/macro";
import insufficientFundsPng from 'assets/images/pages/send-funds/insufficient-funds.png';
import { StyledOtpButtonWrapper } from 'components/common/Otp/styles';
import { Loading } from "components/common";
import ReviewDetail from './ReviewDetail';
import { v4 as uuidv4 } from 'uuid';

const StyledTransaction = styled.div`
  text-align: center;
  h2 {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;
    color: #111326;
    padding: 0 70px;
    
    @media (max-width: 992px) {
      padding: 0 20px;
    }
  }
  p {
    margin-top: 20px;
    font-size: 14px;
    padding: 0 70px;
    line-height: 20px;
  }
`;


const PaymentRequestReview = () => {
    const dispatch = useDispatch();
    const {
        fromAccount,
        transaction_details,
        loading,
        paymentRequestStatus,
    } = selectState();
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [isError, setIsError] = React.useState(false);
    const clientNonce = uuidv4()

    useEffect(() => {
        if (paymentRequestStatus === 'error') {
            setIsError(true);
        }
        return () => {
            setIsError(false);
        }
    }, [isError, paymentRequestStatus]);


    const submitPaymentRequest = useCallback(() => {
        dispatch(actions.createPaymentRequest({ 
            fromAccount, 
            payment_request_details: {
                ...transaction_details,
                client_nonce: clientNonce,
            },
        }));
    }, [transaction_details, fromAccount, dispatch, clientNonce ]);

    function cancelRequest() {
        setIsModalOpen(false);
    }

    const requestConfirmation = () => {
        setIsModalOpen(true);
    };


    return (
        <Grid item xs={12} md={12} lg={12} sm={12} position={'relative'}>
            {loading && <Loading className={cx(styles.fundsLoading)} />}
            <ReviewDetail/>
            <Grid container mt={5}>
                <Grid item md={12} xs={12} sm={12} lg={12} xl={6} className={cx(styles['review-step'])}>
                    <Button
                        className={cx(styles['review-button'], styles['submitButton'])}
                        onClick={requestConfirmation}
                        size="lg"
                        variant="gradient"
                        text="Confirm Payment Request"
                        disabled={loading}
                    />
                </Grid>
            </Grid>
            <Dialog
                fullWidth
                open={isModalOpen}
                isSingleButton={false}
                className={'recipients-modal'}
                setOpen={() => setIsModalOpen(!isModalOpen)}
                submitButton={!isError ?'Confirm': null}
                cancelButton={!isError ?'Cancel': null}
                submitAction={submitPaymentRequest}
                cancelAction={cancelRequest}
                isActions={true}
                backdropProps={{
                    style: {
                        backgroundColor: 'rgba(255,255,255, 0.8)'
                    }
                }}
            >
                <StyledTransaction>
                    {!isError ?
                        <>
                          <h2> Are you sure you want to create the payment request?</h2>
                          <p>Note: Any payment request that is not approved or rejected within 45 days will automatically expire.</p>
                        </>
                        :
                        <>
                            <h2>Insufficient funds. Please select another amount and try again</h2>
                            <img src={insufficientFundsPng} alt={"insufficient-funds"} style={{ maxWidth: '100%', width: '164px', height: '164px', margin: "20px 0" }} />
                            <StyledOtpButtonWrapper>
                                <Button
                                    onClick={() => {
                                        dispatch(actions.resetPaymentRequestStatus());
                                        setIsModalOpen(!isModalOpen)
                                    }}
                                    size="lg"
                                    variant="gradient"
                                    text={'Try Again'}
                                />
                            </StyledOtpButtonWrapper>
                        </>
                    }
                </StyledTransaction>
            </Dialog>
        </Grid>
    );
};

export default PaymentRequestReview;