import {call, put, takeLatest} from 'redux-saga/effects';
import {actions} from "./slice";
import Endpoints from 'utils/endpoints';
import { request } from 'utils/api';

const { updatePassword } = Endpoints.auth;

export function* changePassword(action) {
    try {
        const apiKeys = yield call(request().post, updatePassword.url, {
            old_password: action.payload.currentPassword,
            new_password: action.payload.password
        });
         if(apiKeys && apiKeys.data && apiKeys.status === 200) {
            yield put(actions.changePasswordSuccess());
         } else if(apiKeys.status === 404){
            yield put(actions.changePasswordError({
                message: 'The current password does not match this console user’s password.',
                status: apiKeys.status,
            }));
         } else {
            yield put(actions.changePasswordError({
                message: 'Your password could not be updated. Please retry in a few minutes.',
                status: apiKeys.status,
            }));
         }
    } catch (err) {
        const errorStatus = err.response.status;
        if(errorStatus === 404){
            yield put(actions.changePasswordError({
                message: 'The current password does not match this console user’s password.',
                status: errorStatus,
            }));
         } else {
            yield put(actions.changePasswordError({
                message: 'Your password could not be updated. Please retry in a few minutes.',
                status: errorStatus,
            }));
         }
    }
}

export function* profileSaga() {
    yield takeLatest(actions.changePassword.type, changePassword);
}


