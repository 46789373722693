import { createSlice } from '@reduxjs/toolkit'

/*
 *  @desc The payments slice manages the state of the pending payments
 *  slice
 *  reducer
 *  actions
 */

export const initialState = {
    loading: false,
    error: {
        status: false,
        message: ''
    },
    changePassword: {
        status: null,
    }
}

export const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        clearState(state) {
            state.loading = false;
            state.error = {
                message: '',
                status: false,
            };
            state.changePassword = {
                status: null,
            }
        },
        changePassword(state) {
            state.loading = true;
            state.error = {
                message: '',
                status: false,
            };
        },
        changePasswordSuccess(state, action) {
            state.loading = false;
            state.changePassword = {
                status: 'success'
            }
            state.error = {
                message: '',
                status: false,
            };
        },
        changePasswordError(state, action) {
            state.loading = false;
            state.changePassword = {
                status: 'failed'
            }
            state.error = {...action.payload};
        },
    }
})

export const { actions, reducer, name } = profileSlice;
