import { UserType, Roles } from "utils/auth";

export const getAuthData = state => state.auth;
export const getUserRole = state => state.auth?.login?.roles ?? [];
export const getPasswordData = state => state.password;
export const getForgotPasswordData = state => state.forgotPassword;
export const checkEmailExists = state => state.checkEmail.data;

const containsRoles = (userRoles, expectedRoles) => {
    if (expectedRoles?.length === 0) {
        return false;
    }
    return expectedRoles?.every((role) => {
        return userRoles?.includes(role)
    })
} 

const containsAny = (userRoles, expectedRoles) => {
    if (expectedRoles?.length === 0) {
        return false;
    }
    return expectedRoles?.some((role) => {
        return userRoles?.includes(role)
    })
} 

export const getUserTypes = (state) => {
    const viewerPairedRoles = [
        Roles.ExternalUser, 
        Roles.MakerAll, 
        Roles.ApproverAll,
        Roles.InstantPayerAll
    ];
    var roles = state.auth?.login?.roles ?? [];
    if (roles?.length === 0) {
        return [UserType.BasicUser];
    }
    if (roles?.length === 1 && roles[0] === Roles.BasicUser) {
        return [UserType.BasicUser];
    }

    var userTypes = [];

    if (!containsAny(roles, viewerPairedRoles) 
            && containsRoles(roles, [Roles.ViewerAll])) {
        userTypes.push(UserType.Viewer);
    }

    if (containsRoles(roles, [Roles.ExternalUser])){
        userTypes.push(UserType.ExternalUser);
    }

    if (containsRoles(roles, [Roles.ViewerAll, Roles.MakerAll])){
        userTypes.push(UserType.Maker);
    }
    if (containsRoles(roles, [Roles.ViewerAll, Roles.ApproverAll])){
        userTypes.push(UserType.Approver);
    }
    if (containsRoles(roles, [Roles.ViewerAll, Roles.InstantPayerAll])){
        userTypes.push(UserType.InstantPayer);
    }
    if (containsRoles(roles, [Roles.Developer])){
        userTypes.push(UserType.Developer);
    }
    if (userTypes.length > 0) {
        return userTypes;
    }
    return [UserType.BasicUser];
}