import {call,  put, select, takeLatest } from 'redux-saga/effects';
import {
    actions
} from "./slice";
import { request } from 'utils/api';
import Endpoints from 'utils/endpoints';
import { getCurrencyData } from 'store/selectors/currency';

export function* getDefaultAccount({payload}) {
    const { accounts: {accounts} } = Endpoints;
    try {
        const {currency} = yield select(getCurrencyData);
        const getAccount = yield call(request().get, accounts.url(payload.type, currency));
        if(getAccount) {
            yield put(actions.getAccountSuccess(getAccount.data));
        }
    } catch (err) {
        yield put(
            actions.getAccountError(err.message
            ));
    }
}

export function* getTransactionsFee(action) {
    const { account_id, amount } = action.payload;
    const { url } = Endpoints.accounts.account
    ;
    try {
        const getTransactionFee = yield call(request().get, `${url}/${account_id}/transaction-fee?amount=${amount}`);
        if(getTransactionFee) {
            yield put(actions.getTransactionFeeSuccess(getTransactionFee.data));
        }
    } catch (err) {
        yield put(
            actions.getTransactionFeeError(err.message
            ));
    }
}

export function* getOtp(action) {

    try {
        const {
            data,
        } = yield call(
            request().post,
            'auth/generate-transaction-otp',
            action.payload,
        );
        if(data) {
            yield put(actions.getOtpSuccess(data));
        }
    } catch (err) {
        yield put(
            actions.getOtpError(err.message
            ));
    }
}

export function* fetchFromAccount(action) {
    const {url} = Endpoints.accounts.account;
    const account_id = action.payload;
    try {
        const { data } = yield call(request().get, `${url}/${account_id}`);
        if(data) {
            yield put(actions.fetchFromAccountSuccess(data));
        } else {
            yield put(actions.fetchFromAccountFailure({
                error: 'Invalid data',
            }));
        }
    } catch (err) {
        const {
            message
        } = err.response;
        yield put(
            actions.fetchFromAccountFailure({
                error: message,
            })
        );
    }
}

export function* createTransaction(action) {
    const { url } = Endpoints.accounts.account;

    try {
        const {
            data,
        } = yield call(
            request().post,
            `${url}/${action.payload.fromAccount.id}/transaction`,
            {...action.payload.transaction_details},
            {
                headers: {
                    "X_OTP_CODE": action.payload.OTP,
                    "X_OTP_TOKEN": action.payload.token
                },
            },
        );
        if(data) {
           yield put(actions.createTransactionSuccess());
        }
    } catch (err) {
        const {
            data,
            status,
        } = err.response;

        if (status === 403) {
            yield put(
                actions.createTransactionError({
                    message: 'OTP missmatch',
                    status: status,
                    }
                ));    
        } else {
            yield put(
                actions.createTransactionError({
                    message: data.message ?  data.message : data.detail,
                    status: status,
                    }
                ));    
        }
    }
}

export function* createPaymentRequest(action) {
    const { url } = Endpoints.accounts.account;

    try {
        const {
            data,
        } = yield call(
            request().post,
            `${url}/${action.payload.fromAccount.id}/payment-request`,
            {...action.payload.payment_request_details},
        );
        if(data) {
            yield put(actions.createPaymentRequestSuccess());
        }
    } catch (err) {
        const {
            data,
            status,
        } = err.response;
        yield put(
            actions.createPaymentRequestError({
                message: data.message ?  data.message : data.detail,
                status: status,
                }
            ));    
    }
}

export function* sendFundsSaga() {
    yield takeLatest(actions.getAccount.type, getDefaultAccount);
    yield takeLatest(actions.getTransactionFee.type, getTransactionsFee);
    yield takeLatest(actions.getOtp.type, getOtp);
    yield takeLatest(actions.createTransaction.type, createTransaction);
    yield takeLatest(actions.fetchFromAccount.type, fetchFromAccount);
    yield takeLatest(actions.createPaymentRequest.type, createPaymentRequest);
}