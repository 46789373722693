import React from 'react';
import {useSelector} from "react-redux";
import {Grid} from "@mui/material";
import cx from "classnames";
import styles from "./payments.module.scss";
import NumberFormat from "react-number-format";
import { getCurrencyData } from 'store/selectors/currency';

const  PendingPaymentReview = ({payment}) => {
    const {currency} = useSelector(getCurrencyData);
    return (
        <Grid item xs={12} md={12} lg={12} sm={12} className={cx(styles['pendingPaymentReview'])}>
                <Grid container mb={4}>
                    <Grid item md={6} xs={6} sm={6} lg={6} xl={6}>
                        <h2 className={cx(styles['stepper-title'])}>
                            Review Payment Details
                        </h2>
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item md={5} xs={12} sm={5} lg={5} xl={5} className={cx(styles['review-step'])}>
                        <div className={cx(styles['review-sub-title'])}>
                            <div>Transfer details</div>
                        </div>
                        <div className={cx(styles['review-detail-label'])}>
                            <div>You send:</div>
                            <span className={cx(styles['review-detail-item'])} style={{ float: 'right' }}>
                                  {payment &&
                                      <NumberFormat
                                          value={parseFloat(payment.amount).toFixed(2)}
                                          thousandSeparator={true}
                                          displayType={'text'} />
                                  } {currency}
                            </span>
                        </div>
                        <div className={cx(styles['review-detail-label'])}>
                            <div>Recipient receives:</div>
                            <div className={cx(styles['review-detail-item'])} style={{ float: 'right' }}>
                                {payment &&
                                    <NumberFormat
                                        value={parseFloat(payment.amount).toFixed(2)}
                                        thousandSeparator={true}
                                        displayType={'text'} />
                                } {currency}
                            </div>
                        </div>
                        <div className={cx(styles['review-detail-label'])}>
                            <div>Reference:</div>
                            <div className={cx(styles['review-detail-item'])} style={{ float: 'right' }}>
                                {payment && payment.reference}
                            </div>
                        </div>
                        {/* <div className={cx(styles['review-detail-label'])}>
                            <div>Balance after transfer:</div>
                            <div className={cx(styles['review-detail-item'])} style={{ float: 'right' }}>
                                {(payment && fromAccount) && (
                                    <NumberFormat
                                        value={parseFloat(parseFloat(fromAccount.balance) - parseFloat(payment.amount)).toFixed(2)}
                                        thousandSeparator={true}
                                        displayType={'text'} />
                                    )} {currency}
                            </div>
                        </div> */}
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item md={5} xs={12} sm={5} lg={5} xl={5} className={cx(styles['review-step'])}>
                        <div className={cx(styles['review-sub-title'])}>
                            <div>Remitter details</div>
                        </div>
                        {payment?.source_account_name && <div className={cx(styles['review-detail-label'])}>
                            <div>Name:</div>
                            <div className={cx(styles['review-detail-item'])} style={{ float: 'right' }}>
                            {payment?.source_account_name}
                            </div>
                        </div>}
                        {payment?.source_account_number && <div className={cx(styles['review-detail-label'])}>
                            <div>Account Number:</div>
                            <div className={cx(styles['review-detail-item'])} style={{ float: 'right' }}>
                                {payment && payment.source_account_number}
                            </div>
                        </div>}
                        { payment?.source_account_sort_code && <div className={cx(styles['review-detail-label'])}>
                            <div>Sort code:</div>
                            <div className={cx(styles['review-detail-item'])} style={{ float: 'right' }}>
                                {payment ? payment.source_account_sort_code : "-"}
                            </div>
                        </div>}
                        {payment?.source_account_iban && <div className={cx(styles['review-detail-label'])}>
                            <div>IBAN:</div>
                            <div className={cx(styles['review-detail-item'])} style={{ float: 'right' }}>
                                {payment && payment.source_account_iban}
                            </div>
                        </div>}
                        {payment?.source_account_bic && <div className={cx(styles['review-detail-label'])}>
                            <div>BIC:</div>
                            <div className={cx(styles['review-detail-item'])} style={{ float: 'right' }}>
                                {payment && payment.source_account_bic}
                            </div>
                        </div>}
                    </Grid>
                    <Grid item md={5} xs={12} sm={5} lg={5} xl={5} className={cx(styles['review-step'])}>
                        <div className={cx(styles['review-sub-title'])}>
                            <div>Recipient details</div>
                        </div>
                        <div className={cx(styles['review-detail-label'])}>
                            <div>Name:</div>
                            <div className={cx(styles['review-detail-item'])} style={{ float: 'right' }}>
                                {payment && payment.destination_account_name}
                            </div>
                        </div>
                        <div className={cx(styles['review-detail-label'])}>
                            <div style={{minWidth: 150}}>Email Address:</div>
                            <div className={cx(styles['review-detail-item'])} style={{ float: 'right' }}>
                                {payment ? payment.recipient_email : "-"}
                            </div>
                        </div>
                        {payment?.destination_account_number && <div className={cx(styles['review-detail-label'])}>
                            <div>Account Number:</div>
                            <div className={cx(styles['review-detail-item'])} style={{ float: 'right' }}>
                                {payment && payment.destination_account_number}
                            </div>
                        </div>}
                        { payment?.destination_account_sort_code && <div className={cx(styles['review-detail-label'])}>
                            <div>Sort code:</div>
                            <div className={cx(styles['review-detail-item'])} style={{ float: 'right' }}>
                                {payment ? payment.destination_account_sort_code : "-"}
                            </div>
                        </div>}
                        {payment?.destination_account_iban && <div className={cx(styles['review-detail-label'])}>
                            <div>IBAN:</div>
                            <div className={cx(styles['review-detail-item'])} style={{ float: 'right' }}>
                                {payment && payment.destination_account_iban}
                            </div>
                        </div>}
                        {payment?.destination_account_bic && <div className={cx(styles['review-detail-label'])}>
                            <div>BIC:</div>
                            <div className={cx(styles['review-detail-item'])} style={{ float: 'right' }}>
                                {payment && payment.destination_account_bic}
                            </div>
                        </div>}
                    </Grid>
                </Grid>
        </Grid>
    );
}

export default PendingPaymentReview;