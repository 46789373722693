import React, {useCallback, useEffect, useState} from 'react';
import {Grid} from "@mui/material";
import {Button, InputField, SelectField} from "components/common";
import {paymentRequestStatuses, paymentRequestTypes} from "./mockData";
import {useFormik} from "formik";
import * as yup from "yup";
import cx from "classnames";
import styles from "./payments.module.scss";
import RangePicker from 'components/common/Picker/RangePicker';
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {getQueryParams} from "utils/helpers";
import moment from "moment/moment";

const validationSchema = yup.object({
    type: yup.string('Enter payment type'),
    status: yup.string('Enter payment status'),
    from_date: yup.date().nullable(),
    to_date: yup.date().nullable(),
});

const PendingPaymentsFilter = ({loading, onFilter}) => {
    const [reference, setReference] = useState('');
    const [username, setUsername] = useState('');
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const addQuery = useCallback((values) => {
        let pathname = location.pathname;
        const params = getQueryParams(values)
        navigate({
            pathname: pathname,
            search: params.toLocaleLowerCase()
        });
    }, [location, navigate]);


    const formik = useFormik({
        initialValues: {
            type: searchParams.get('type') || 'payout',
            status: 'all',
            from_date: searchParams.get('from_date') || moment().subtract(50,'d').format('YYYY-MM-DD'),
            to_date: searchParams.get('to_date') || moment().format('YYYY-MM-DD'),
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            if(values && !loading) {
                addQuery(values)
            }
        },
        onReset: () => {
            searchParams.set('type',  'payout');
            searchParams.delete('status');
            searchParams.set('from_date',  moment().subtract(50, 'd').format('YYYY-MM-DD'));
            searchParams.set('to_date',  moment().format('YYYY-MM-DD'));
            navigate({
                pathname: location.pathname,
                search: searchParams.toString().toLocaleLowerCase()
            })
        }
    })

    useEffect(() => {
        addQuery({
            ...formik.values, 
            reference: searchParams.get('reference'),
            username: searchParams.get('username'),
        });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const data = Object.fromEntries([...searchParams]);
        if(Object.keys(data).length) {
            formik.setValues(prev => ({...prev, ...data}));
            onFilter(searchParams.toString());
        }
        if(searchParams.get('reference')){
            setReference(searchParams.get('reference'));
        }
        if(searchParams.get('username')){
            setUsername(searchParams.get('username'));
        }
        // eslint-disable-next-line
    }, [searchParams]);

    const handleSelect = useCallback((name, value) => {
        formik.setValues(prev => ({...prev, [name]: value}))
    }, [formik])

    const handlePickerChange = useCallback((date) => {
        formik.setValues(prev => ({
            ...prev,
            from_date: date[0] || prev.from_date,
            to_date: date[1] || prev.to_date,
        }))
    }, [formik])

    const handleSearch = useCallback(() => {
        var query = {...formik.values};
        if(reference && !loading){
            query = {...query, reference};
        }
        if(username && !loading) {
            query = {...query, username};
        }
        addQuery(query);
    }, [addQuery, reference, username, loading, formik]);

    const handleClear = useCallback(() => {
        setReference('');
        setUsername('');
        if (searchParams.has('reference')) {
            searchParams.delete('reference');
        }
        if (searchParams.has('username')) {
            searchParams.delete('username');
        }
    }, [searchParams]);


    return (
        <Grid container className={styles.filter}>
                <div className={styles.filterContainer}>
                    <SelectField
                        name={'type'}
                        label={'Payment type'}
                        options={paymentRequestTypes}
                        onChange={handleSelect}
                        value={formik.values.type}
                        error={formik.touched.type && Boolean(formik.errors.type)}
                        helperText={formik.touched.type && formik.errors.type}
                        className={'paymentSearchField'}
                    />
                </div>
                <div className={styles.filterContainer}>
                    <SelectField
                        name={'status'}
                        label={'Payment status'}
                        options={paymentRequestStatuses}
                        onChange={handleSelect}
                        value={formik.values.status}
                        error={formik.touched.status && Boolean(formik.errors.status)}
                        helperText={formik.touched.status && formik.errors.status}
                        className={'paymentSearchField'}
                    />
                </div>
                <div className={styles.filterPicker}>
                    <RangePicker
                        values={[
                            new Date(formik.values.from_date),
                            new Date(formik.values.to_date)
                        ]}
                        onChange={handlePickerChange}
                        // reset={resetDates}
                    />
                </div>
            <div className={styles.filterSearch}>
                <InputField
                    placeholder="Search by payment reference"
                    hasIcon
                    fullWidth
                    iconName="search"
                    iconPosition="start"
                    value={reference}
                    className={'paymentsFilter'}
                    onChange={(e) => setReference(e.target.value)}
                />
                <InputField
                    placeholder="Search by user"
                    hasIcon
                    fullWidth
                    iconName="users"
                    iconPosition="start"
                    value={username}
                    className={'paymentsFilter'}
                    onChange={(e) => setUsername(e.target.value)}
                />
                <Button
                    text="Search"
                    // disabled={!search?.length}
                    className={cx(styles.searchButton)}
                    variant="outlined"
                    size="sm"
                    onClick={handleSearch}
                />
                <Button
                    text="Clear"
                    className={cx(styles.searchButton)}
                    variant="outlined"
                    size="sm"
                    onClick={() => {
                        handleClear();
                        formik.handleReset();
                    }}
                />
            </div>
        </Grid>
    );
};

export default PendingPaymentsFilter;